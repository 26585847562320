/* @flow */
import React, { Component } from "react";
import { connect } from "react-redux";

import Loading from "./loading";
import NarrativeItineraries from "./narrative-itineraries";

import { getActiveSearch } from "../../util/state";

type Props = {
  pending: ?boolean
};

class NarrativeRoutingResults extends Component<Props> {
  render() {
    const { pending } = this.props;
    if (pending) return <Loading />;

    return <NarrativeItineraries />;
  }
}

const mapStateToProps = (state, ownProps) => {
  const activeSearch = getActiveSearch(state.otp);

  return {
    pending: activeSearch && activeSearch.pending,
    routingType: activeSearch && activeSearch.query.routingType
  };
};

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NarrativeRoutingResults);
