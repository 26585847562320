//@flow
import React, { Component } from "react";

import { Tag } from "@blueprintjs/core";

type Props = {
  leg: {
    routeShortName?: string,
    routeLongName?: string,
    routeColor?: string,
    routeTextColor?: string
  },
  fallbackToLongName?: boolean
};

export default class RouteTag extends Component<Props> {
  render() {
    const { leg, fallbackToLongName } = this.props;

    if (!leg.routeShortName) {
      return null;
    }
    return (
      <Tag
        round={true}
        style={
          leg.routeColor && leg.routeTextColor
            ? {
                backgroundColor: `#${leg.routeColor}`,
                color: `#${leg.routeTextColor}`,
                fontWeight: 700
              }
            : null
        }
      >
        {leg.routeShortName || (fallbackToLongName && leg.routeLongName)}
      </Tag>
    );
  }
}
