import { createAction } from "redux-actions";

import { formChanged } from "./form";

/* SET_LOCATION action creator. Updates a from or to location in the store
 *
 * payload format: {
 *   type: 'from' or 'to'
 *   location: {
 *     name: (string),
 *     lat: (number)
 *     lat: (number)
 *   }
 */

export const clearingLocation = createAction("CLEAR_LOCATION");
export const settingLocation = createAction("SET_LOCATION");
export const switchingLocations = createAction("SWITCH_LOCATIONS");

export function clearLocation(payload) {
  return function(dispatch, getState) {
    dispatch(clearingLocation(payload));
    dispatch(formChanged());
  };
}

export function setLocation(payload) {
  return function(dispatch, getState) {
    const otpState = getState().otp;

    // update the location in the store
    dispatch(settingLocation(payload));
    dispatch(formChanged());

    // reverse geocode point location if requested
    if (payload.reverseGeocode) {
      const { baseUrl, accessToken } = otpState.config.mapboxGeocoder;
      const { location: point } = payload;
      console.log(point);
      fetch(
        `${baseUrl}/${point.lon},${point.lat}.json?access_token=${accessToken}`
      )
        .then(resp => resp.json())
        .then(json => {
          const data = json.features || [];
          const latestState = getState().otp;
          // override location name if reverse geocode is successful
          payload.location.name = data[0].place_name.replace(", Canada", "");

          const latestPos =
            (latestState.currentQuery &&
              latestState.currentQuery[payload.type]) ||
            {};
          // Ensure that we're still on the same point before re-setting location.
          if (
            latestPos.lat === payload.location.lat &&
            latestPos.lon === payload.location.lon
          ) {
            dispatch(
              settingLocation({
                type: payload.type,
                location: Object.assign({}, payload.location, {
                  name: payload.location.name
                }),
                allowMapPan: payload.allowMapPan
              })
            );
          }
        })
        .catch(err => {
          console.error(err);
        });
    }
  };
}

/* payload is simply { type: 'from'|'to' }; location filled in automatically */

export function setLocationToCurrent(payload) {
  return function(dispatch, getState) {
    const currentPosition = getState().otp.location.currentPosition;
    if (currentPosition.error || !currentPosition.coords) return;
    payload.location = {
      lat: currentPosition.coords.latitude,
      lon: currentPosition.coords.longitude,
      name: "(Current Location)"
    };
    dispatch(settingLocation(payload));
    dispatch(formChanged());
  };
}

export function switchLocations() {
  return function(dispatch, getState) {
    const { from, to } = getState().otp.currentQuery;
    dispatch(
      settingLocation({
        type: "from",
        location: to
      })
    );
    dispatch(
      settingLocation({
        type: "to",
        location: from
      })
    );
    dispatch(formChanged());
  };
}
