// @flow
import React, { Component } from "react";
import { Button } from "@blueprintjs/core";
import { connect } from "react-redux";

import { setViewedTrip } from "../../actions/ui";

type Props = {|
  fromIndex?: number,
  tripId?: string,
  text?: string,
  toIndex?: number,
  setViewedTrip: ({}) => any
|};

class ViewTripButton extends Component<Props> {
  _onClick = () => {
    this.props.setViewedTrip({
      tripId: this.props.tripId,
      fromIndex: this.props.fromIndex,
      toIndex: this.props.toIndex
    });
  };

  render() {
    return (
      <Button className="view-trip-button" onClick={this._onClick}>
        {this.props.text || "Show Route"}
      </Button>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {};
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setViewedTrip: trip => {
      dispatch(setViewedTrip(trip));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewTripButton);
