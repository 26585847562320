/* @flow */
import lonlat from "@conveyal/lonlat";
import haversine from "haversine";
import { debounce } from "lodash";
import React, { Component } from "react";
import { FormGroup, Menu, Position } from "@blueprintjs/core";
import { Suggest, ItemRenderer } from "@blueprintjs/select";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Fuse from "fuse.js";

import {
  setLocation,
  setLocationToCurrent,
  clearLocation
} from "../../actions/map";
import { addLocationSearch, getCurrentPosition } from "../../actions/location";

interface IAddress {
  lon: number;
  lat: number;
  name: string;
  type: string;
}

function escapeRegExpChars(text: string) {
  return text.replace(/([.*+?^=!:${}()|[]\/\\])/g, "\\$1");
}

function highlightText(text: string, query: string) {
  let lastIndex = 0;
  const words = query
    .split(/\s+/)
    .filter(word => word.length > 0)
    .map(escapeRegExpChars);
  if (words.length === 0) {
    return [text];
  }
  const regexp = new RegExp(words.join("|"), "gi");
  const tokens: React.ReactNode[] = [];
  while (true) {
    const match = regexp.exec(text);
    if (!match) {
      break;
    }
    const length = match[0].length;
    const before = text.slice(lastIndex, regexp.lastIndex - length);
    if (before.length > 0) {
      tokens.push(before);
    }
    lastIndex = regexp.lastIndex;
    tokens.push(<strong key={lastIndex}>{match[0]}</strong>);
  }
  const rest = text.slice(lastIndex);
  if (rest.length > 0) {
    tokens.push(rest);
  }
  return tokens;
}

const AddressSuggest = Suggest.ofType<IAddress>();

type Props = {
  config?: Object,
  currentPosition?: Position | {| error: string |},
  hideExistingValue?: boolean,
  location?: Object,
  label?: string,
  sessionSearches?: Array<any>,
  // show autocomplete options as fixed/inline element rather than dropdown
  static?: boolean,
  stopsIndex?: Object,
  // replace with locationType?
  type?: string,
  // callbacks
  onClick?: Function,
  onLocationSelected?: Function,
  // dispatch
  addLocationSearch?: Function,
  clearLocation?: Function,
  setLocation?: Function,
  setLocationToCurrent?: Function
};

const fuseOpts = {
  keys: ["name", "code"],
  shouldSort: true,
  minMatchCharLength: 4,
  tokenize: true,
  matchAllTokens: true
};

type State = {};

class LocationField extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      value:
        props.location !== null && !props.hideExistingValue
          ? props.location.name
          : "",
      menuVisible: false,
      geocodedFeatures: [],
      searchedStops: [],
      listItems: [],
      activeIndex: null
    };
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.location !== nextProps.location) {
      this.setState({
        value: nextProps.location !== null ? nextProps.location.name : "",
        geocodedFeatures: [],
        searchedStops: [],
        listItems: []
      });
    }
  }

  _geocodeAutocomplete(text) {
    if (!text) {
      this.setState({
        geocodedFeatures: [],
        searchedStops: []
      });
      this.autocompleteDataSource();
      return;
    }

    const completeSearch = text => {
      const fuse = new Fuse(Object.values(this.props.stopsIndex), fuseOpts);
      return Promise.resolve(fuse.search(text).slice(0, 3));
    };
    const {
      baseUrl,
      accessToken,
      bbox,
      proximity,
      country,
      types
    } = this.props.config.mapboxGeocoder;
    Promise.all([
      fetch(
        `${baseUrl}/${text}.json?autocomplete=true&access_token=${accessToken}&bbox=${bbox}&proximity=${proximity}&country=${country}&types=${types}`
      ).then(resp => resp.json()),
      completeSearch(text)
    ])
      .then(result => {
        this.setState({
          geocodedFeatures: result[0].features || [],
          searchedStops: result[1]
        });
      })
      .then(result => {
        this.autocompleteDataSource();
      })
      .catch(err => {
        console.error(err);
      });
  }

  _setLocation(location) {
    if (typeof this.props.onLocationSelected === "function") {
      this.props.onLocationSelected(location);
    }
    this.props.setLocation(this.props.type, location);
  }

  _useCurrentLocation = () => {
    const {
      currentPosition,
      getCurrentPosition,
      setLocationToCurrent,
      type
    } = this.props;
    if (currentPosition && currentPosition.coords) {
      // We already have geolocation coordinates
      setLocationToCurrent(type);
    } else {
      // Call geolocation.getCurrentPosition and set as from/to type
      this.setState({ fetchingLocation: true });
      getCurrentPosition(type);
    }
  };

  autocompleteDataSource = () => {
    // Assemble menu contents, to be displayed either as dropdown or static panel.
    // Menu items are created in four phases: (1) the current location, (2) any
    // geocoder search results; (3) nearby transit stops; and (4) saved searches
    let list = [];

    /* 1) Process the current location */
    const { currentPosition } = this.props;

    let geocodedFeatures = this.state.geocodedFeatures;
    if (geocodedFeatures.length > 5)
      geocodedFeatures = geocodedFeatures.slice(0, 3);

    /* 2) Process geocode search result option(s) */
    if (geocodedFeatures.length > 0) {
      // Iterate through the geocoder results
      geocodedFeatures.forEach(feature => {
        list.push({
          ...lonlat(feature.geometry.coordinates),
          name: feature.place_name
            .replace(", Canada", "")
            .replace(", Ontario ", " ON "),
          type: `geocoded_${feature.place_type[0]}`
        });
      });
    }

    const searchedStops = this.state.searchedStops;
    if (searchedStops.length > 0) {
      // Iterate through the found nearby stops
      searchedStops.forEach(stop => {
        list.push({
          ...lonlat(stop),
          name: `${stop.name} (Stop ${stop.code}) `,
          type: "stop"
        });
      });
    }

    let sessionSearches = this.props.sessionSearches;
    if (sessionSearches.length > 5)
      sessionSearches = sessionSearches.slice(0, 3);

    /* 4) Process recent search history options */
    if (sessionSearches.length > 0) {
      // Iterate through any saved locations
      sessionSearches.forEach(location => {
        list.push({
          ...lonlat(location),
          name: location.name,
          type: "session_searches"
        });
      });
    }

    if (!currentPosition.error && currentPosition.coords) {
      // current position detected successfully
      const options = {
        format: "{lon,lat}"
      };
      const currentCoords = {
        lon: currentPosition.coords.longitude,
        lat: currentPosition.coords.latitude
      };
      list.sort((a, b) => {
        const distToA = haversine(currentCoords, a, options);
        const distToB = haversine(currentCoords, b, options);
        return distToA - distToB;
      });

      list.unshift({
        ...lonlat(currentPosition.coords),
        name: "Current Location",
        type: "current_location"
      });
    }

    this.setState({
      listItems: list.filter(
        (value, index, self) =>
          self.findIndex(x => x.name === value.name) === index
      )
    });
  };

  _debouncedGeocodeAutocomplete = debounce(this._geocodeAutocomplete, 360);

  onChange = value => {
    this.setState({ value: value });
    this._debouncedGeocodeAutocomplete(value);
  };

  onSelect = value => {
    if (value) {
      this._setLocation(value);
      // this.setState({ value: option.key });
      // Add to the location search history
      this.props.addLocationSearch(value);
      this.setState({
        value: value.name
      });
    }
  };

  getIconForType = (type: string) => {
    let icon = "map-pin";
    if (type === "current_location") icon = "globe-americas";
    if (type === "stop") icon = "bus";
    return <FontAwesomeIcon icon={icon} />;
  };

  renderAddress: ItemRenderer<IAddress> = (
    address,
    { handleClick, modifiers, query }
  ) => {
    if (!modifiers.matchesPredicate) {
      return null;
    }
    return (
      <Menu.Item
        active={modifiers.active}
        disabled={false}
        label={this.getIconForType(address.type)}
        key={`address-${address.type}-${address.name}`}
        onClick={handleClick}
        text={highlightText(address.name, query)}
      />
    );
  };

  render() {
    const { currentPosition, label, type, getCurrentPosition } = this.props;

    /** the text input element **/
    const placeholder =
      currentPosition.fetching === type
        ? "Fetching location..."
        : label || type;

    const { value, listItems } = this.state;

    // Autosuggest will pass through all these props to the input.
    const inputProps = {
      "aria-label": type,
      placeholder,
      onFocus: () => {
        getCurrentPosition();
        this._debouncedGeocodeAutocomplete();
      },
      fill: true
    };

    return (
      <FormGroup
        inline
        label={
          <FontAwesomeIcon
            icon={type === "from" ? "map-marker" : "flag-checkered"}
            fixedWidth
            title={type}
          />
        }
      >
        <AddressSuggest
          inputProps={inputProps}
          onQueryChange={q => {
            this.onChange(q);
          }}
          itemRenderer={this.renderAddress}
          items={listItems}
          inputValueRenderer={x => x.title}
          noResults={<Menu.Item disabled={true} text="No results." />}
          onItemSelect={x => {
            this.onSelect(x);
          }}
          popoverProps={{
            minimal: true,
            // https://github.com/palantir/blueprint/issues/2352
            modifiers: {
              preventOverflow: { enabled: false },
              hide: { enabled: false }
            },
            position: Position.BOTTOM_LEFT,
            fill: true
          }}
          query={value}
        />
      </FormGroup>
    );
  }
}

// connect to redux store

const mapStateToProps = (state, ownProps) => {
  return {
    config: state.otp.config,
    location: state.otp.currentQuery[ownProps.type],
    currentPosition: state.otp.location.currentPosition,
    sessionSearches: state.otp.location.sessionSearches,
    stopsIndex: state.otp.transitIndex.stops
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    addLocationSearch: location => {
      dispatch(addLocationSearch({ location }));
    },
    getCurrentPosition: type => dispatch(getCurrentPosition(type)),
    setLocation: (type, location) => {
      dispatch(setLocation({ type, location, reverseGeocode: !location.name }));
    },
    setLocationToCurrent: type => {
      dispatch(setLocationToCurrent({ type }));
    },
    clearLocation: type => {
      dispatch(clearLocation({ type }));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LocationField);
