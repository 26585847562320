/* @flow */
import React, { Component } from "react";
import { Button, Collapse } from "@blueprintjs/core";
import { connect } from "react-redux";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { setMainPanelContent, setViewedRoute } from "../../actions/ui";
import { findRoutes, findRoute } from "../../actions/api";
import { routeComparator } from "../../util/itinerary";

type Props = {
  hideBackButton?: boolean,
  routes?: Object
};

class RouteViewer extends Component {
  props: Props;

  _backClicked = () => {
    this.props.setMainPanelContent(null);
    this.props.setViewedRoute(null);
  };

  componentWillMount() {
    this.props.findRoutes();
  }

  render() {
    const {
      routes,
      viewedRoute,
      hideBackButton,
      findRoute,
      setViewedRoute
    } = this.props;

    return (
      <div className="route-viewer">
        {/* Header Block */}
        <div className="route-viewer-header">
          {/* Back button */}
          {!hideBackButton && (
            <div className="back-button-container">
              <Button size="small" onClick={this._backClicked}>
                <FontAwesomeIcon icon="arrow-left" />
                Back
              </Button>
            </div>
          )}

          {/* Header Text */}
          <div className="header-text">Route Viewer</div>
          <div style={{ clear: "both" }} />
        </div>

        <div className="route-viewer-body">
          {routes &&
            Object.values(routes)
              .sort(routeComparator)
              .map(route => {
                const isActiveRoute =
                  viewedRoute && viewedRoute.routeId === route.id;
                let activeRouteData;
                if (isActiveRoute) {
                  activeRouteData = routes[viewedRoute.routeId];
                }
                return (
                  <div
                    style={{ borderBottom: "1px solid gray" }}
                    key={route.id}
                  >
                    <Button
                      className="clear-button-formatting"
                      style={{ padding: 8 }}
                      onClick={() => {
                        findRoute({ routeId: route.id });
                        setViewedRoute({ routeId: route.id });
                      }}
                    >
                      <b>{route.shortName}</b> {route.longName}
                    </Button>
                    <Collapse isOpen={isActiveRoute}>
                      <div style={{ padding: 8 }}>
                        {activeRouteData.url && (
                          <a
                            href={activeRouteData.url}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Route Details
                          </a>
                        )}
                      </div>
                    </Collapse>
                  </div>
                );
              })}
        </div>
      </div>
    );
  }
}

// connect to redux store

const mapStateToProps = (state, ownProps) => {
  return {
    routes: state.otp.transitIndex.routes,
    viewedRoute: state.otp.ui.viewedRoute
  };
};

const mapDispatchToProps = {
  findRoute,
  findRoutes,
  setMainPanelContent,
  setViewedRoute
};

export default connect(mapStateToProps, mapDispatchToProps)(RouteViewer);
