/* @flow */
import React, { Component } from "react";
import moment from "moment";
import { connect } from "react-redux";
import { Button } from "@blueprintjs/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

type Props = {
  caret?: string,
  compressed?: boolean,
  date?: string,
  departArrive?: string,
  time?: moment,
  onClick?: Function,
  routingType?: string
};

class DateTimePreview extends Component {
  props: Props;

  render() {
    const { caret, date, time, departArrive, routingType } = this.props;
    let timeStr;
    let dayName = "Weekday";
    if (routingType === "ITINERARY") {
      const d = moment(date);

      const isWeekend = d.day() === 0 || d.day() === 6;

      if (isWeekend && d.day() === 0) {
        dayName = "Sunday";
      } else if (isWeekend && d.day() === 6) {
        dayName = "Saturday";
      }

      if (departArrive === "NOW") timeStr = "Leave now";
      else if (departArrive === "ARRIVE")
        timeStr = `Arrive by ${time.format("h:mm a")}`;
      else if (departArrive === "DEPART")
        timeStr = `Depart at ${time.format("h:mm a")}`;
    }

    const summary = (
      <div className="details">
        <FontAwesomeIcon icon="calendar" fixedWidth />{" "}
        {/*
          moment(date)
            .calendar()
            .split(" ")[0]
        */}
        {[dayName, "service"].join(" ")}
        <br />
        <FontAwesomeIcon icon="clock" fixedWidth /> {timeStr}
      </div>
    );

    const button = (
      <div className="button-container">
        <Button
          className="change-button"
          onClick={this.props.onClick}
          rightIcon={caret && <FontAwesomeIcon icon={`chevron-${caret}`} />}
        >
          Edit
        </Button>
      </div>
    );

    return this.props.compressed ? (
      /* 'compressed' layout -- button is below selected mode preview */ <div className="date-time-preview compressed">
        {summary}
        {button}
      </div>
    ) : (
      /* 'wide' layout -- button and selected mode preview are side-by-side  */ <div className="date-time-preview wide">
        {button}
        {summary}
        <div style={{ clear: "both" }} />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const {
    departArrive,
    date,
    time,
    routingType,
    startTime,
    endTime
  } = state.otp.currentQuery;
  return {
    config: state.otp.config,
    routingType,
    departArrive,
    date,
    time,
    startTime,
    endTime
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(DateTimePreview);
