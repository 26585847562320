import * as Sentry from "@sentry/browser";

const sentry = store => {
  Sentry.addGlobalEventProcessor(event => {
    // line below assumes that username is stores under user in the state
    const { sessionId, ...state } = store.getState();

    return {
      ...event,
      extra: {
        ...event.extra,
        "redux:state": state
      },
      user: {
        session: sessionId
      }
    };
  });

  return next => action => {
    Sentry.addBreadcrumb({
      category: "redux-action",
      message: action.type
    });

    return next(action);
  };
};

export default sentry;
