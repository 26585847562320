/* @flow */
import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "@blueprintjs/core";

import { getModeIcon } from "../../util/itinerary";

type Props = {
  active?: boolean,
  label?: string,
  mode?: string,
  icons?: Object,
  onClick?: Function
};

export default class ModeButton extends Component<Props> {
  props: Props;

  render() {
    const { active, icons, label, mode, onClick } = this.props;
    const buttonColor = active ? "#000" : "#bbb";
    return (
      <div className="mode-button-container">
        <Button
          className="mode-button"
          onClick={onClick}
          title={label}
          style={{ borderColor: buttonColor }}
          icon={getModeIcon(mode, icons)}
        />
        <div className="mode-label" style={{ color: buttonColor }}>
          {label}
        </div>
        {active && (
          <div>
            <div className="mode-check" style={{ color: "white" }}>
              <FontAwesomeIcon icon="circle" />
            </div>
            <div className="mode-check" style={{ color: "red" }}>
              <FontAwesomeIcon icon="check-circle" />
            </div>
          </div>
        )}
      </div>
    );
  }
}
