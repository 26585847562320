// Colours from https://blueprintjs.com/docs/#core/colors
const colourLut = {
  "900": "#1D7324",
  "1800": "#529A53",
  "2700": "#82C283",
  "3600": "#B1ECB5"
};

export function getIsochroneColor(time) {
  const timeStr = time.toString();
  return colourLut[timeStr] || "#000000";
}
