/* @flow */
import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Alignment, H4 } from "@blueprintjs/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  setActiveItinerary,
  setActivePreviewItinerary,
  setActiveLeg,
  setActiveStep
} from "../../actions/narrative";
import ItineraryDetails from "./default/itinerary-details";
import ItinerarySummary from "./default/itinerary-summary";
import ShareLink from "./share-link";
import { getActiveItineraries, getActiveSearch } from "../../util/state";

type Props = {
  itineraries?: Array<any>,
  pending?: boolean,

  activeItinerary?: ?number,
  activePreviewItinerary?: ?number,
  setActiveItinerary: (index: ?number) => any,
  setActivePreviewItinerary: (index: ?number) => any,
  setActiveLeg?: Function,
  setActiveStep?: Function
};

type State = {
  hidden: boolean
};

const hideShowButton = props => {
  const { onClick, style, hidden } = props;
  return (
    <Button
      onClick={() => onClick()}
      icon={<FontAwesomeIcon icon={hidden ? "chevron-down" : "chevron-up"} />}
      style={style}
      text={hidden ? "Show" : "Hide"}
      minimal
    />
  );
};

const itineraryButton = props => {
  const { itinerary, index, showCloseIcon, ...restProps } = props;
  return (
    <Button
      active={false}
      minimal={true}
      fill={true}
      alignText={Alignment.LEFT}
      {...restProps}
    >
      <ItinerarySummary
        key={`itinerary-summary-${index}`}
        itinerary={itinerary}
        rightIcon={showCloseIcon ? <FontAwesomeIcon icon="times" /> : null}
      />
    </Button>
  );
};

class NarrativeItineraries extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      hidden: window.innerWidth <= 800 || window.innerHeight <= 600
    };
  }

  render() {
    const {
      activeItinerary,
      activePreviewItinerary,
      itineraries,
      setActiveItinerary
    } = this.props;
    if (!itineraries) return null;

    if (
      activeItinerary != null &&
      itineraries &&
      itineraries[activeItinerary]
    ) {
      return (
        <div className="itinerary">
          {itineraryButton({
            itinerary: itineraries[activeItinerary],
            index: activeItinerary,
            onClick: () => setActiveItinerary(null),
            showCloseIcon: true
          })}
          {hideShowButton({
            style: { float: "left" },
            hidden: this.state.hidden,
            onClick: () => {
              this.setState({ hidden: !this.state.hidden });
            }
          })}
          <ShareLink style={{ float: "right" }} />
          <div className="clearfix" />
          {!this.state.hidden && (
            <ItineraryDetails
              index={activeItinerary}
              active={true}
              itinerary={itineraries[activeItinerary]}
              activeLeg={this.props.activeLeg}
              activeStep={this.props.activeStep}
              setActiveLeg={this.props.setActiveLeg}
              setActiveStep={this.props.setActiveStep}
            />
          )}
        </div>
      );
    }
    return (
      <div className="itineraries">
        <H4>We found {itineraries.length} itineraries: </H4>

        {itineraries.map((itinerary, index) => {
          return (
            <div key={`itin-list-idx-${index}`}>
              {itineraryButton({
                itinerary,
                index,
                active: index === activePreviewItinerary,
                onClick: () => setActiveItinerary(index),
                onMouseOver: () => setActivePreviewItinerary(index),
                onMouseLeave: () => setActivePreviewItinerary(null),
                showCloseIcon: false
              })}
            </div>
          );
        })}
      </div>
    );
  }
}

// connect to the redux store

const mapStateToProps = (state, ownProps) => {
  const activeSearch = getActiveSearch(state.otp);
  // const { activeItinerary, activeLeg, activeStep } = activeSearch ? activeSearch.activeItinerary : {}
  const pending = activeSearch ? activeSearch.pending : false;
  const itineraries = getActiveItineraries(state.otp);
  return {
    itineraries,
    pending,
    activeItinerary:
      activeSearch &&
      activeSearch.activeItinerary !== null &&
      activeSearch.activeItinerary,
    activePreviewItinerary:
      activeSearch &&
      activeSearch.activePreviewItinerary !== null &&
      activeSearch.activePreviewItinerary,
    activeLeg: activeSearch && activeSearch.activeLeg,
    activeStep: activeSearch && activeSearch.activeStep
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setActiveItinerary: index => {
      dispatch(setActiveItinerary({ index }));
    },
    setActivePreviewItinerary: index => {
      dispatch(setActivePreviewItinerary({ index }));
    },
    setActiveLeg: (index, leg) => {
      dispatch(setActiveLeg({ index, leg }));
    },
    setActiveStep: (index, step) => {
      dispatch(setActiveStep({ index, step }));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NarrativeItineraries);
