/* @flow */
import React, { Component } from "react";
import Divicon from "@lighthouse/react-leaflet-div-icon";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { throttle } from "lodash";

import { constructLocation } from "../../util/map";

type Props = {
  setLocation: ({}) => any,
  type: "from" | "true",
  location: ?{ lat: number, lon: number, name: ?string }
};

export default class Endpoint extends Component<Props> {
  _onDrag = throttle(
    e => {
      const { setLocation, type } = this.props;
      const location = constructLocation(e.target.getLatLng());
      setLocation({ type, location, reverseGeocode: true, allowMapPan: false });
    },
    250,
    { leading: false }
  );

  _onDragEnd = e => {
    this._onDrag.cancel();
    const { setLocation, type } = this.props;
    const location = constructLocation(e.target.getLatLng());
    setLocation({ type, location, reverseGeocode: true, allowMapPan: true });
  };

  render() {
    const { type, location } = this.props;
    const position =
      location && location.lat && location.lon
        ? [location.lat, location.lon]
        : null;
    if (!position) return null;
    const iconType = type === "from" ? "map-marker" : "flag-checkered";
    const baseStyle = {
      width: "32px",
      height: "32px"
    };
    const icon = (
      <Divicon
        draggable
        position={position}
        onDrag={this._onDrag}
        onDragEnd={this._onDragEnd}
      >
        <span
          title={location && location.name}
          className={`fa-layers fa-fw endpoint-${type}-icon`}
          style={{ opacity: 1.0 }}
        >
          <FontAwesomeIcon
            icon={iconType}
            style={{
              color: "#000",
              fontSize: "32px",
              ...baseStyle
            }}
          />
        </span>
      </Divicon>
    );
    return icon;
  }
}
