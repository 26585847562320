/* @flow */
import React, { Component } from "react";
import { FeatureGroup, GeoJSON } from "react-leaflet";
import polyline from "@mapbox/polyline";

import { isTransit } from "../../util/itinerary";

type Props = {
  itinerary?: Object,
  activeLeg?: number,
  setActiveLeg?: Function
};

export default class ItineraryLegs extends Component {
  props: Props;
  _onLegClick = e => {
    const index = e.layer.feature.geometry.index;
    const leg = this.props.itinerary.legs[index];
    if (index === this.props.activeLeg) {
      this.props.setActiveLeg(null);
    } else {
      this.props.setActiveLeg(index, leg);
    }
  };
  render() {
    const { itinerary, activeLeg } = this.props;
    return (
      <FeatureGroup>
        {itinerary.legs.map((leg, index) => {
          const geojson = polyline.toGeoJSON(leg.legGeometry.points);
          geojson.index = index;
          const active = activeLeg === index;

          let color = "gray";
          if (isTransit(leg.mode)) {
            color = leg.routeColor ? `#${leg.routeColor}` : "blue";
          }

          const style = feature => {
            return {
              // GRT SPECIFIC
              weight: leg.mode === "TRAM" ? 8 : 5,
              stroke: active ? "yellow" : color,
              fill: color,
              fillOpacity: 0
            };
          };

          return (
            <GeoJSON
              key={Math.random()}
              color={color}
              onClick={this._onLegClick}
              data={geojson}
              style={style}
            />
          );
        })}
      </FeatureGroup>
    );
  }
}
