import { debounce } from "lodash";
import { createAction } from "redux-actions";

import { routingQuery, isochroneQuery } from "./api";
import {
  queryIsValid,
  isochroneQueryIsValid,
  getQueryAsURLParams
} from "../util/state";

export const settingQueryParam = createAction("SET_QUERY_PARAM");
export const changingForm = createAction("FORM_CHANGED");

/**
 * Action to update any specified query parameter. Replaces previous series of
 * parameter-specific actions.
 */
export function setQueryParam(payload) {
  return function(dispatch, getState) {
    dispatch(settingQueryParam(payload));
    dispatch(formChanged());
  };
}

let debouncedPlanTrip; // store as variable here, so it can be reused.
let debouncedIsochrone;
let lastDebouncePlanTimeMs;
let lastDebouncedIsochroneTimeMs;

export function formChanged() {
  return function(dispatch, getState) {
    dispatch(changingForm());
    const otpState = getState().otp;
    const { debouncePlanTimeMs, debounceIsochroneTimeMs } = otpState.config;
    // check if a trip plan should be made
    if (queryIsValid(otpState)) {
      // trip plan should be made

      // check if debouncing function needs to be (re)created
      if (!debouncedPlanTrip || lastDebouncePlanTimeMs !== debouncePlanTimeMs) {
        debouncedPlanTrip = debounce(
          () => dispatch(routingQuery()),
          debouncePlanTimeMs
        );
        lastDebouncePlanTimeMs = debouncePlanTimeMs;
      }
      debouncedPlanTrip();
    } else if (isochroneQueryIsValid(otpState)) {
      if (
        !debouncedIsochrone ||
        lastDebouncedIsochroneTimeMs !== debounceIsochroneTimeMs
      ) {
        debouncedIsochrone = debounce(
          () => dispatch(isochroneQuery()),
          debounceIsochroneTimeMs
        );
        lastDebouncedIsochroneTimeMs = debounceIsochroneTimeMs;
      }
      debouncedIsochrone();
    }

    if ((queryIsValid(otpState) || isochroneQueryIsValid(otpState)) && window) {
      window.location.hash = window.encodeURI(
        getQueryAsURLParams(otpState.currentQuery)
      );
    }
  };
}
