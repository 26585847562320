import moment from "moment";

/**
 * Formats an elapsed time duration for display in narrative
 * TODO: internationalization
 * @param {number} seconds duration in seconds
 * @returns {string} formatted text representation
 */
export function formatDuration(seconds) {
  const dur = moment.duration(seconds, "seconds");
  let text = "";
  if (dur.hours() > 0) text += `${dur.hours()} hr${dur.hours() > 1 ? "s" : ""}`;
  if (dur.hours() > 0 && dur.minutes() > 0) text += " ";
  if (dur.minutes() > 0)
    text += `${dur.minutes()} min${dur.minutes() > 1 ? "s" : ""}`;

  if (dur.hours() === 0 && dur.minutes() === 0) {
    return "less than 1 min";
  }

  return text;
}

/**
 * Formats a time value for display in narrative
 * TODO: internationalization/timezone
 * @param {number} ms epoch time value in milliseconds
 * @returns {string} formatted text representation
 */
export function formatTime(ms) {
  return moment(ms).format("h:mm a");
}

/**
 * Formats a stop time value for display in narrative
 * TODO: internationalization/timezone
 * @param {number} seconds time since midnight in seconds
 * @returns {string} formatted text representation
 */
export function formatStopTime(seconds) {
  return moment()
    .startOf("day")
    .seconds(seconds)
    .format("h:mm a");
}
