/* @flow */
import React, { Component } from "react";
import { FormGroup, HTMLSelect } from "@blueprintjs/core";
import { connect } from "react-redux";

import { setQueryParam } from "../../actions/form";

type Props = {
  name?: string,
  value?: string | number,
  label?: string,
  options?: Array<any>,
  setQueryParam?: Function
};

class DropdownSelector extends Component<Props> {
  _onQueryParamChange = event => {
    this.props.setQueryParam({ [this.props.name]: event.currentTarget.value });
  };

  render() {
    const { value, label, options } = this.props;

    return (
      <FormGroup label={label}>
        <HTMLSelect value={value} onChange={this._onQueryParamChange}>
          {options.map((o, i) => (
            <option key={i} value={o.value}>
              {o.text}
            </option>
          ))}
        </HTMLSelect>
      </FormGroup>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {};
};

const mapDispatchToProps = { setQueryParam };

export default connect(mapStateToProps, mapDispatchToProps)(DropdownSelector);
