/* @flow */
import React, { Component } from "react";
import { Button, Tag, Intent, H4 } from "@blueprintjs/core";
import { connect } from "react-redux";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { clearViewedTrip } from "../../actions/ui";
import { findTrip } from "../../actions/api";
import { setLocation } from "../../actions/map";

import { formatStopTime } from "../../util/time";

type Props = {
  hideBackButton?: boolean,
  tripData?: Object,
  viewedTrip?: Object
};

class TripViewer extends Component {
  props: Props;

  _backClicked = () => {
    this.props.clearViewedTrip();
  };

  componentWillMount() {
    this.props.findTrip({ tripId: this.props.viewedTrip.tripId });
  }

  render() {
    const { viewedTrip, tripData, hideBackButton } = this.props;
    if (!tripData) return null;

    return (
      <div className="trip-viewer">
        {/* Header Block */}
        <div className="trip-viewer-header">
          {/* Back button */}
          {!hideBackButton && (
            <div className="back-button-container">
              <Button
                icon={<FontAwesomeIcon icon="arrow-left" />}
                onClick={this._backClicked}
                alt="go back"
              />
            </div>
          )}

          {/* Header Text */}
          <H4>
            Route {tripData.route.shortName}: {tripData.route.longName}
          </H4>
          {/* Basic Trip Info */}

          <div>
            {/* Wheelchair/bike accessibility Tags, if applicable */}
            {tripData.wheelchairAccessible === 1 && (
              <Tag intent={Intent.PRIMARY}>Accessible</Tag>
            )}{" "}
            {tripData.bikesAllowed === 1 && (
              <Tag intent={Intent.PRIMARY}>Bikes Allowed</Tag>
            )}
          </div>
        </div>

        <div className="trip-viewer-body">
          {/* Stop Listing */}
          {tripData.stops &&
            tripData.stopTimes &&
            tripData.stops.map((stop, i) => {
              // determine whether to use special styling for first/last stop
              let stripMapLineClass = "strip-map-line";
              if (i === 0) stripMapLineClass = "strip-map-line-first";
              else if (i === tripData.stops.length - 1)
                stripMapLineClass = "strip-map-line-last";

              // determine whether to show highlight in strip map
              let highlightClass;
              if (i === viewedTrip.fromIndex)
                highlightClass = "strip-map-highlight-first";
              else if (i > viewedTrip.fromIndex && i < viewedTrip.toIndex)
                highlightClass = "strip-map-highlight";
              else if (i === viewedTrip.toIndex)
                highlightClass = "strip-map-highlight-last";

              return (
                <div key={i}>
                  {/* the departure time */}
                  <div className="stop-time">
                    {formatStopTime(tripData.stopTimes[i].scheduledDeparture)}
                  </div>

                  {/* the vertical strip map */}
                  <div className="strip-map-container">
                    {highlightClass && <div className={highlightClass} />}
                    <div className={stripMapLineClass} />
                    <div className="strip-map-icon">
                      <FontAwesomeIcon icon="circle" />
                    </div>
                  </div>

                  {/* the main stop Tag */}
                  <div className="stop-name">{stop.name}</div>

                  <div style={{ clear: "both" }} />
                </div>
              );
            })}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const viewedTrip = state.otp.ui.viewedTrip;
  return {
    tripData: state.otp.transitIndex.trips[viewedTrip.tripId],
    viewedTrip
  };
};

const mapDispatchToProps = {
  clearViewedTrip,
  findTrip,
  setLocation
};

export default connect(mapStateToProps, mapDispatchToProps)(TripViewer);
