/* @flow */
import React from "react";
import { connect } from "react-redux";
import { FeatureGroup, MapLayer, Popup, CircleMarker } from "react-leaflet";
import { LeafletConsumer } from "react-leaflet";
import { Button, ButtonGroup, H4 } from "@blueprintjs/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { hasTransit } from "../../util/itinerary";
import { findStopsWithinBBox, clearStops } from "../../actions/api";
import { setLocation } from "../../actions/map";

type Props = {
  minZoom?: number,
  queryMode?: string,
  stops?: Array<any>,
  refreshStops?: Function
};

class StopsOverlay extends MapLayer {
  props: Props;

  static defaultProps = {
    minZoom: 15
  };

  componentDidMount() {
    // set up pan/zoom listener
    this.context.map.on("moveend", () => {
      this._refreshStops();
    });
  }

  // TODO: determine why the default MapLayer componentWillUnmount() method throws an error
  componentWillUnmount() {}

  _refreshStops() {
    if (this.context.map.getZoom() < this.props.minZoom) {
      this.forceUpdate();
      return;
    }

    try {
      const bounds = this.context.map.getBounds();

      const params = {
        minLat: bounds.getSouth(),
        maxLat: bounds.getNorth(),
        minLon: bounds.getWest(),
        maxLon: bounds.getEast()
      };
      setTimeout(() => {
        this.props.refreshStops(params);
      }, 300);
    } catch (e) {
      console.error(e);
    }
  }

  createLeafletElement() {}

  updateLeafletElement() {}

  render() {
    const { minZoom, queryMode, setLocation, stops } = this.props;

    // don't render if below zoom threshold or transit not currently selected
    if (
      this.context.map.getZoom() < minZoom ||
      !hasTransit(queryMode) ||
      !stops ||
      stops.length === 0
    )
      return <FeatureGroup />;

    // TODO: fix type=success
    return (
      <FeatureGroup>
        {stops.map(stop => {
          const idArr = stop.id.split(":");

          return (
            <CircleMarker
              key={stop.id}
              center={[stop.lat, stop.lon]}
              radius={4}
              fillOpacity={1}
              fillColor="#fff"
              color="#000"
              weight={1.5}
            >
              <Popup>
                <div>
                  <H4>{stop.name}</H4>
                  <div>
                    <b>Stop ID:</b> {idArr[1]}
                  </div>
                  <div style={{ marginTop: "10px", width: "220px" }}>
                    {/* The "Set as [from/to]" ButtonGroup */}
                    <span>Set as: </span>
                    <ButtonGroup>
                      <Button
                        icon={<FontAwesomeIcon icon="map-marker" />}
                        onClick={() => {
                          setLocation(constructPayload(stop, "from"));
                          this.context.map.closePopup();
                        }}
                      >
                        Start
                      </Button>
                      <Button
                        icon={<FontAwesomeIcon icon="flag-checkered" />}
                        onClick={() => {
                          setLocation(constructPayload(stop, "to"));
                          this.context.map.closePopup();
                        }}
                      >
                        End
                      </Button>
                    </ButtonGroup>
                  </div>
                </div>
              </Popup>
            </CircleMarker>
          );
        })}
      </FeatureGroup>
    );
  }
}
StopsOverlay.contextType = LeafletConsumer;

function constructPayload(stop, type) {
  return {
    type,
    location: {
      lat: stop.lat,
      lon: stop.lon,
      name: stop.name
    },
    reverseGeocode: false
  };
}

// connect to the redux store

const mapStateToProps = (state, ownProps) => {
  return {
    stops: state.otp.overlay.transit.stops,
    queryMode: state.otp.currentQuery.mode
  };
};

const mapDispatchToProps = {
  refreshStops: findStopsWithinBBox,
  clearStops,
  setLocation
};

export default connect(mapStateToProps, mapDispatchToProps)(StopsOverlay);
