/* @flow */
import React, { Component } from "react";
import { Button } from "@blueprintjs/core";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { getModeIcon } from "../../util/itinerary";

type Props = {
  // component props
  caret?: string,
  compressed?: boolean,
  icons?: Object,
  showCaret?: boolean,
  onClick?: Function,
  // application state
  modeGroups?: Array<any>,
  queryModes?: Array<any>
};

class SettingsPreview extends Component<Props> {
  render() {
    const { caret, icons, modeGroups, queryModes } = this.props;

    let totalModeCount = 0;
    modeGroups.forEach(g => {
      totalModeCount += g.modes.length;
    });

    const selectedModeCount = this.props.queryModes.length;

    const selectedModes = (
      <div className="selected-modes">
        {selectedModeCount === totalModeCount ? (
          <div className="all-selected">All Modes Selected</div>
        ) : (
          <div className="some-selected">
            <div className="some-selected-label">
              {selectedModeCount} Modes Selected
            </div>
            <div className="some-selected-modes">
              {queryModes.map(mode => {
                return (
                  <div className="mode-icon" key={mode}>
                    {getModeIcon(mode, icons)}
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>
    );

    const button = (
      <div className="button-container">
        <Button
          className="settings-button"
          onClick={this.props.onClick}
          rightIcon={caret && <FontAwesomeIcon icon={`chevron-${caret}`} />}
        >
          Edit
        </Button>
      </div>
    );

    return this.props.compressed ? (
      /* 'compressed' layout -- button is below selected mode preview */ <div className="settings-preview compressed">
        {selectedModes}
        {button}
      </div>
    ) : (
      /* 'wide' layout -- button and selected mode preview are side-by-side  */ <div className="settings-preview wide">
        {button}
        {selectedModes}
        <div style={{ clear: "both" }} />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    modeGroups: state.otp.config.modeGroups,
    queryModes: state.otp.currentQuery.mode.split(",")
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(SettingsPreview);
