// @flow
import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, ButtonGroup } from "@blueprintjs/core";

import DateTimeSelector from "./date-time-selector";
import { setQueryParam } from "../../actions/form";

type Props = {|
  routingType: string,
  setQueryParam: () => any
|};

class DateTimeModal extends Component<Props> {
  render() {
    const { routingType, setQueryParam } = this.props;

    const panels = [
      {
        key: "ITINERARY",
        text: "Exact Time",
        component: <DateTimeSelector />
      }
    ];

    let panel = "";
    if (panels.length > 1) {
      panel = (
        <div className="button-row">
          <ButtonGroup>
            {panels.map(panel => {
              return (
                <Button
                  key={panel.key}
                  className={panel.key === routingType ? "selected" : ""}
                  onClick={() => {
                    this.setState({ activePanel: panel.key });
                    setQueryParam({ routingType: panel.key });
                  }}
                >
                  {panel.text}
                </Button>
              );
            })}
          </ButtonGroup>
        </div>
      );
    }

    var selectedPanel = panels.find(p => p.key === routingType);

    return (
      <div className="date-time-modal">
        {panel}
        <div className="main-panel">
          {selectedPanel && selectedPanel.component}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { departArrive, date, time, routingType } = state.otp.currentQuery;
  return {
    config: state.otp.config,
    departArrive,
    date,
    time,
    routingType
  };
};

const mapDispatchToProps = {
  setQueryParam
};

export default connect(mapStateToProps, mapDispatchToProps)(DateTimeModal);
