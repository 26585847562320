/* @flow */
import React, { Component } from "react";
import { connect } from "react-redux";
import { NonIdealState } from "@blueprintjs/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { getActiveSearch } from "../../util/state";

type Props = { error?: { msg: string } };

class ErrorMessage extends Component<Props> {
  render() {
    const { error } = this.props;
    if (!error) return null;

    return (
      <NonIdealState
        icon={<FontAwesomeIcon icon="exclamation-circle" />}
        title="Could Not Plan Trip"
        description={error.msg}
      />
    );
  }
}

// connect to the redux store

const mapStateToProps = (state, ownProps) => {
  const activeSearch = getActiveSearch(state.otp);
  return {
    error: activeSearch && activeSearch.response && activeSearch.response.error
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(ErrorMessage);
