/* @flow */
import React from "react";
import { connect } from "react-redux";
import {
  FeatureGroup,
  MapLayer,
  Polyline,
  LeafletConsumer
} from "react-leaflet";

import polyline from "@mapbox/polyline";

type Props = {};

class RouteViewerOverlay extends MapLayer {
  props: Props;

  componentDidMount() {}

  // TODO: determine why the default MapLayer componentWillUnmount() method throws an error
  componentWillUnmount() {}

  componentWillReceiveProps(nextProps) {
    // helper fn to check if geometry has been populated for all patterns in route
    const isGeomComplete = routeData => {
      return (
        routeData &&
        routeData.patterns &&
        Object.values(routeData.patterns).reduce(
          (acc, ptn) => acc && typeof ptn.geometry !== "undefined",
          true
        )
      );
    };

    // if pattern geometry just finished populating, update the map points
    if (
      !isGeomComplete(this.props.routeData) &&
      isGeomComplete(nextProps.routeData)
    ) {
      const allPoints = Object.values(nextProps.routeData.patterns).reduce(
        (acc, ptn) => {
          return acc.concat(polyline.decode(ptn.geometry.points));
        },
        []
      );
      this.context.map.fitBounds(allPoints);
    }
  }

  createLeafletElement() {}

  updateLeafletElement() {}

  render() {
    const { routeData } = this.props;

    if (!routeData || !routeData.patterns) return <FeatureGroup />;

    const routeColor = routeData.color ? `#${routeData.color}` : "#00bfff";
    return (
      <FeatureGroup>
        {Object.values(routeData.patterns).map(pattern => {
          if (!pattern.geometry) return null;
          const pts = polyline.decode(pattern.geometry.points);
          return (
            <Polyline
              positions={pts}
              weight={4}
              color={routeColor}
              opacity={1}
              key={pattern.id}
            />
          );
        })}
      </FeatureGroup>
    );
  }
}
RouteViewerOverlay.contextType = LeafletConsumer;

// connect to the redux store

const mapStateToProps = (state, ownProps) => {
  const viewedRoute = state.otp.ui.viewedRoute;
  return {
    viewedRoute,
    routeData: viewedRoute
      ? state.otp.transitIndex.routes[viewedRoute.routeId]
      : null
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(RouteViewerOverlay);
