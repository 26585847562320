/* @flow */
import React, { Component } from "react";
import { Button, Intent, Position, Toaster } from "@blueprintjs/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ShareToaster = Toaster.create({
  className: "share-toaster",
  position: Position.TOP
});

export default class ShareLink extends Component<{| style: any |}> {
  onClick() {
    if (navigator.share) {
      navigator.share({
        url: window.location.href
      });
    } else {
      navigator.clipboard
        .writeText(window.location.href)
        .then(() => {
          ShareToaster.show({
            message: "Link copied to clipboard!",
            intent: Intent.SUCCESS
          });
        })
        .catch(e => {
          ShareToaster.show({
            message: "Could not copy link to clipboard",
            intent: Intent.DANGER
          });
        });
    }
  }

  render() {
    return (
      <>
        <Button
          onClick={() => {
            this.onClick();
          }}
          icon={<FontAwesomeIcon icon="share" />}
          style={this.props.style}
          text="Share"
          minimal
        />
      </>
    );
  }
}
