/* @flow */
import React, { Component } from "react";
import { Button, ButtonGroup } from "@blueprintjs/core";

import ModesPanel from "./modes-panel";
import GeneralSettingsPanel from "./general-settings-panel";

type Props = { icons?: Object };

export default class SettingsSelectorPanel extends Component<Props> {
  constructor(props: Props) {
    super(props);
    this.state = { activePanel: "MODES" };
  }

  render() {
    const panels = [
      {
        key: "MODES",
        text: "Modes",
        component: <ModesPanel icons={this.props.icons} />
      },
      {
        key: "GENERAL",
        text: "General",
        component: <GeneralSettingsPanel />
      }
    ];

    return (
      <div className="settings-selector-panel">
        <div className="button-row">
          <ButtonGroup>
            {panels.map(panel => {
              return (
                <Button
                  className={
                    panel.key === this.state.activePanel ? "selected" : ""
                  }
                  onClick={() => this.setState({ activePanel: panel.key })}
                  key={`panel-${panel.key}`}
                >
                  {panel.text}
                </Button>
              );
            })}
          </ButtonGroup>
        </div>
        {panels.find(p => p.key === this.state.activePanel).component}
      </div>
    );
  }
}
