/* @flow */
import React, { Component } from "react";

import BaseMap from "./base-map";
import EndpointsOverlay from "./endpoints-overlay";
import RoutesOverlay from "./routes-overlay";
import StopsOverlay from "./stops-overlay";
import StopViewerOverlay from "./stop-viewer-overlay";
import IsochroneOverlay from "./isochrone-overlay";
import ItineraryOverlay from "./itinerary-overlay";
import TripViewerOverlay from "./trip-viewer-overlay";
import RouteViewerOverlay from "./route-viewer-overlay";

type Props = {};

export default class DefaultMap extends Component<Props> {
  props: Props;
  render() {
    return (
      <BaseMap {...this.props}>
        <TripViewerOverlay />
        <IsochroneOverlay />
        <ItineraryOverlay />
        <RoutesOverlay controlName="Transit Routes" />
        <EndpointsOverlay />
        <StopsOverlay controlName="Transit Stops" visible />
        <StopViewerOverlay />
        <RouteViewerOverlay />
      </BaseMap>
    );
  }
}
