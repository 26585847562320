import React, { Component } from "react";
import { Tag } from "@blueprintjs/core";
import Moment from "moment";
import { connect } from "react-redux";
import { getActiveSearch } from "../../util/state";
import { getIsochroneColor } from "../../util/isochrone";

class IsochroneLegend extends Component<{| isochrones: mixed |}> {
  render() {
    if (
      !this.props.isochrones ||
      !this.props.isochrones.features ||
      this.props.isochrones.features.length === 0
    ) {
      return null;
    }
    Moment.relativeTimeThreshold("m", 55);

    const featureProps = this.props.isochrones.features
      .map(x => x.properties)
      .sort((a, b) => {
        return a.time - b.time;
      });

    const itemMapper = item => {
      const parsedTime = parseInt(item.time, 10);
      return (
        <div key={item.time}>
          <Tag
            style={{
              backgroundColor: getIsochroneColor(item.time),
              marginRight: "10px"
            }}
          />
          <span style={{ height: "100%" }}>
            Within {Moment.duration(parsedTime, "seconds").humanize()}
          </span>
        </div>
      );
    };

    const mappedLegend = featureProps.map(itemMapper);
    return (
      <div>
        {/* <H5>Travel Times</H5> */}
        {mappedLegend}
      </div>
    );
  }
}

// connect to the redux store

const mapStateToProps = (state, ownProps) => {
  const activeSearch = getActiveSearch(state.otp);
  return {
    isochrones: activeSearch && activeSearch.isochroneResponse
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(IsochroneLegend);
