//@flow
import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

type ModeIconProps = {|
  mode:
    | "bus"
    | "tram"
    | "rail"
    | "subway"
    | "walk"
    | "bicycle"
    | "bicycle_walk"
    | "ferry"
    | "gondola"
|};

export default class ModeIcon extends Component<ModeIconProps> {
  iconforMode(mode) {
    switch (mode) {
      case "bus":
        return <FontAwesomeIcon icon="bus" fixedWidth aria-hidden="true" />;
      case "tram":
      case "rail":
      case "subway":
        return <FontAwesomeIcon icon="subway" fixedWidth aria-hidden="true" />;
      case "walk":
        return <FontAwesomeIcon icon="walking" fixedWidth aria-hidden="true" />;
      case "bicycle":
      case "bicycle_rent":
        return <FontAwesomeIcon icon="bicycle" fixedWidth aria-hidden="true" />;
      case "ferry":
        return <FontAwesomeIcon icon="ship" fixedWidth aria-hidden="true" />;
      case "gondola":
        return <GondolaIcon aria-hidden="true" />;
      default:
    }
    return null;
  }

  render() {
    return <span>{this.iconforMode(this.props.mode.toLowerCase())}</span>;
  }
}

class GondolaIcon extends Component {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        version="1.0"
        viewBox="0 0 100 89.383"
        height="100%"
        width="100%"
      >
        <polygon points="100,3.417 100,0 0,38.239 0,41.663 " />
        <path d="M43.871,16.965c2.986,0,5.425-2.467,5.425-5.507c0-3.034-2.439-5.476-5.425-5.476c-3.009,0-5.448,2.442-5.448,5.476  C38.424,14.498,40.864,16.965,43.871,16.965z" />
        <path d="M77.553,45.469H53.319V25.603l-3.385,1.364v18.502H25.477c-1.915,0-3.477,1.576-3.477,3.516V85.86  c0,1.939,1.562,3.523,3.477,3.523h52.077c1.916,0,3.484-1.584,3.484-3.523V48.984C81.038,47.045,79.469,45.469,77.553,45.469z   M40.583,65.495c0,1.052-0.832,1.916-1.871,1.916H27.249c-1.038,0-1.891-0.864-1.891-1.916V53.94c0-1.051,0.854-1.915,1.891-1.915  h11.462c1.038,0,1.871,0.864,1.871,1.915V65.495z M59.211,65.351c0,1.057-0.848,1.892-1.893,1.892H45.887  c-1.047,0-1.893-0.835-1.893-1.892v-11.58c0-1.049,0.846-1.884,1.893-1.884h11.432c1.045,0,1.893,0.835,1.893,1.884V65.351z   M77.696,65.351c0,1.057-0.825,1.892-1.872,1.892H64.371c-1.038,0-1.893-0.835-1.893-1.892v-11.58c0-1.049,0.854-1.884,1.893-1.884  h11.453c1.047,0,1.872,0.835,1.872,1.884V65.351z" />
        <path d="M56.848,12.063c3,0,5.439-2.467,5.439-5.507s-2.439-5.507-5.439-5.507c-2.985,0-5.424,2.467-5.424,5.507  S53.863,12.063,56.848,12.063z" />
      </svg>
    );
  }
}
