// @flow
import * as utm from "utm";

const PROD_URL = "https://routing-feedback.regionofwaterloo.9802690.ca";

const client = opts => {
  return {
    track: (type, payload) => {
      const data = {
        event: type,
        host: window && window.location && window.location.host,
        ...payload
      };

      fetch(`${PROD_URL}/metrics`, {
        method: "POST", // *GET, POST, PUT, DELETE, etc.
        mode: "cors", // no-cors, cors, *same-origin
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          "x-api-key": opts.token
        },
        body: JSON.stringify(data) // body data type must match "Content-Type" header
      })
        .then(() => {})
        .catch(err => {
          console.error(err);
        });
    }
  };
};

const handleAction = (store, next, action, options) => {
  if (!action.meta || !action.meta.analytics) {
    return next(action);
  }

  const state = store.getState().otp;
  if (!state.currentQuery.allowMapPan) {
    return next(action);
  }

  const activeSearch = state.searches[state.activeSearchId];
  const activeSearchResponse = activeSearch && activeSearch.response;
  const activeSearchResponsePlan =
    activeSearchResponse && activeSearchResponse.plan;
  const itineraries =
    activeSearchResponsePlan &&
    activeSearchResponsePlan["itineraries"].map(itin => {
      return {
        duration: itin.duration,
        endTime: itin.endTime,
        startTime: itin.startTime,
        fare: itin.fare,
        transfers: itin.transfers,
        transitTime: itin.transitTime,
        transferStops: itin.legs
          .filter(x => x.transitLeg)
          .map(x => x.to)
          .slice(0, -1),
        legs: itin.legs.map(x => {
          return {
            mode: x.mode,
            distance: x.distance,
            duration: x.duration,
            route: x.route
          };
        }),
        walkDistance: itin.walkDistance,
        waitingTime: itin.waitingTime,
        walkTime: itin.walkTime
      };
    });

  const fromUtm =
    state.currentQuery.from &&
    utm.fromLatLon(state.currentQuery.from.lat, state.currentQuery.from.lon);
  const toUtm =
    state.currentQuery.to &&
    utm.fromLatLon(state.currentQuery.to.lat, state.currentQuery.to.lon);

  const { eventType, eventPayload } = action.meta.analytics;
  let payload = {
    ...eventPayload,
    sessionId: state.sessionId,
    from: state.currentQuery.from && {
      ...state.currentQuery.from,
      utm: `${fromUtm.zoneNum}${fromUtm.zoneLetter} ${Math.round(
        fromUtm.easting
      )} ${Math.round(fromUtm.northing)}`
    },
    to: state.currentQuery.to && {
      ...state.currentQuery.to,
      utm: `${toUtm.zoneNum}${toUtm.zoneLetter} ${Math.round(
        toUtm.easting
      )} ${Math.round(toUtm.northing)}`
    },
    date: state.currentQuery.date,
    time: state.currentQuery.time.format("HH:mm"),
    itineraries: JSON.stringify(itineraries)
  };

  client(options).track(eventType, payload);

  return next(action);
};

export function createAnalytics(options = {}) {
  return store => next => action => handleAction(store, next, action, options);
}
