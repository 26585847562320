/* @flow */
import React, { Component } from "react";
import { connect } from "react-redux";

import { Collapse } from "@blueprintjs/core";
import { Tab, Tabs } from "@blueprintjs/core";

import DateTimeModal from "./date-time-modal";
import LocationField from "./location-field";
import DateTimePreview from "./date-time-preview";
import SettingsPreview from "./settings-preview";
import SettingsSelectorPanel from "./settings-selector-panel";
import SwitchClearButton from "./switch-clear-button";
import { getActiveSearch } from "../../util/state";

type Props = {
  icons?: Object,
  mobile?: boolean,
  activeItinerary: ?number,
  hasActiveSearch: boolean
};

type State = {
  desktopDateTimeExpanded: boolean,
  desktopSettingsExpanded: boolean,
  selectedTabId: ?string,
  hasActiveSearch: boolean,
  hasActiveTo: boolean
};

class DefaultSearchForm extends Component<Props, State> {
  static defaultProps = {
    showFrom: true,
    showTo: true
  };

  constructor(props: Props) {
    super(props);
    this.state = {
      desktopDateTimeExpanded: false,
      desktopSettingsExpanded: false,
      hasActiveSearch: props.hasActiveSearch,
      selectedTabId: props.hasActiveSearch
        ? "form-picker-results"
        : "form-picker-settings"
    };
  }

  static getDerivedStateFromProps(nextProps: Props, prevState: State) {
    if (prevState.hasActiveSearch !== nextProps.hasActiveSearch) {
      return {
        selectedTabId: nextProps.hasActiveSearch
          ? "form-picker-results"
          : "form-picker-settings",
        hasActiveSearch: nextProps.hasActiveSearch
      };
    }

    return null;
  }

  props: Props;

  render() {
    const {
      icons,
      mobile,
      hasActiveSearch,
      hasActiveTo,
      activeItinerary,

      results
    } = this.props;
    const actionText = mobile ? "long press" : "right-click";

    if (activeItinerary != null) {
      return results;
    }

    const settingsPanel = (
      <>
        <div aria-label="Date and time settings">
          <DateTimePreview
            caret={this.state.desktopDateTimeExpanded ? "up" : "down"}
            onClick={() => {
              this.setState({
                desktopDateTimeExpanded: !this.state.desktopDateTimeExpanded
              });
            }}
          />
          <Collapse isOpen={this.state.desktopDateTimeExpanded}>
            <DateTimeModal />
          </Collapse>
        </div>
        <div aria-label="Routing and navigation settings">
          <SettingsPreview
            icons={icons}
            caret={this.state.desktopSettingsExpanded ? "up" : "down"}
            onClick={() => {
              this.setState({
                desktopSettingsExpanded: !this.state.desktopSettingsExpanded
              });
            }}
          />
          <Collapse isOpen={this.state.desktopSettingsExpanded}>
            <SettingsSelectorPanel icons={icons} />
          </Collapse>
        </div>
      </>
    );

    return (
      <form>
        <div className="locations">
          <LocationField
            type="from"
            label={`Enter origin or ${actionText} on map...`}
          />

          <LocationField
            type="to"
            label={`Enter destination or ${actionText} on map...`}
          />

          <div className="switch-button-container">
            <SwitchClearButton icon="exchange-alt" action="switch" />
            <SwitchClearButton
              icon="undo"
              action="clear"
              style={{ float: "right" }}
            />
          </div>
        </div>
        <hr />
        <Tabs
          id="form-picker"
          selectedTabId={this.state.selectedTabId}
          onChange={(newTabId, prevTabId, e) => {
            if (newTabId !== prevTabId) {
              this.setState({
                selectedTabId: newTabId
              });
            }
          }}
        >
          <Tab
            id="form-picker-settings"
            title="Options"
            panel={settingsPanel}
          />
          {hasActiveSearch && (
            <Tab
              id="form-picker-results"
              title={hasActiveTo ? "Itineraries" : "Travel Times"}
              panel={results}
            />
          )}
        </Tabs>
      </form>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const activeSearch = getActiveSearch(state.otp);
  return {
    hasActiveTo: !!(
      activeSearch &&
      activeSearch.query &&
      activeSearch.query.to
    ),
    hasActiveSearch: !!(
      activeSearch &&
      activeSearch.query &&
      activeSearch.query.from
    ),
    activeItinerary:
      activeSearch && activeSearch.activeItinerary != null
        ? activeSearch.activeItinerary
        : null
  };
};

export default connect(mapStateToProps)(DefaultSearchForm);
