// @flow
import React, { Component } from "react";
import { connect } from "react-redux";
import { Dialog, Button, Intent, Classes } from "@blueprintjs/core";

import { clearLocation } from "../../actions/map";

// TODO: make menu items configurable via props/config
type Props = {
  resetState: (payload: any) => any
};

type CookieWarningState = {| visible: boolean |};

class CookieWarning extends Component<{||}, CookieWarningState> {
  state = {
    visible:
      ((window &&
        window.localStorage &&
        window.localStorage.getItem("clearedPrivacyPrompt")) ||
        (window &&
          window.sessionStorage &&
          window.sessionStorage.getItem("clearedPrivacyPrompt"))) !== "true"
  };

  onClose = () => {
    // store state
    this.setState({
      visible: false
    });
    window &&
      window.localStorage &&
      window.localStorage.setItem("clearedPrivacyPrompt", "true");
    window &&
      window.sessionStorage &&
      window.sessionStorage.setItem("clearedPrivacyPrompt", "true");
  };

  render() {
    return (
      <Dialog
        title="Privacy Notice"
        canEscapeKeyClose={false}
        canOutsideClickClose={false}
        isOpen={this.state.visible}
      >
        <div className={Classes.DIALOG_BODY}>
          Your trip information is being saved for us to better improve service.
          Continued use of this tool constitutes agreement with our{" "}
          <a
            href="https://www.regionofwaterloo.ca/en/regional-government/website-privacy-statement.aspx"
            target="_blank"
            rel="noopener noreferrer"
          >
            Privacy Statement
          </a>
          .
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button
              style={{
                marginRight: 8
              }}
              onClick={this.onClose}
              intent={Intent.PRIMARY}
            >
              Continue
            </Button>
          </div>
        </div>
      </Dialog>
    );
  }
}

class AppMenu extends Component<Props> {
  render() {
    return (
      <div className="app-menu">
        <CookieWarning />
        <h1 style={{ color: "#fff" }}>
          <a
            href="/"
            onClick={this.props.resetState}
            onKeyDown={this.props.resetState}
            role="button"
          >
            <img
              src="/logo.png"
              alt="Grand River Transit logo"
              style={{ maxHeight: "40px" }}
            />
          </a>
          &nbsp; Trip Planner
        </h1>
      </div>
    );
  }
}

// connect to the redux store

const mapStateToProps = (state, ownProps) => {
  return {};
};

const mapDispatchToProps = dispatch => {
  return {
    resetState: () => {
      dispatch(clearLocation({ type: "from" }));
      dispatch(clearLocation({ type: "to" }));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AppMenu);
