// import moment from 'moment'
import React, { Component } from "react";

import {
  FormGroup,
  ButtonGroup,
  Button,
  ControlGroup
} from "@blueprintjs/core";
import { TimePicker } from "@blueprintjs/datetime";
import { connect } from "react-redux";
import moment from "moment";

import { setQueryParam } from "../../actions/form";

type Props = {
  date?: string,
  departArrive?: string,
  time?: string,
  location?: {},
  label?: string,
  profile?: boolean,
  startTime?: string,
  endTime?: string,

  setQueryParam?: () => any,
  type?: string // replace with locationType?
};

class DateTimeSelector extends Component<Props> {
  _onDepartChange = value => {
    this.props.setQueryParam({ departArrive: value });
  };

  _onDayOfWeekChange = value => {
    this.props.setQueryParam({
      date: moment(new Date().toISOString().split("T")[0])
        .weekday(value)
        .format("YYYY-MM-DD")
    });
  };

  _onTimeChange = value => {
    this.props.setQueryParam({ time: moment(value) });
  };

  render() {
    const { departArrive, date, time } = this.props;
    const options = [
      /*"NOW", */ { text: "Depart at", value: "DEPART" },
      { text: "Arrive by", value: "ARRIVE" }
    ];

    const dowOptions = [
      {
        text: "Weekday",
        weekday: 3
      },
      {
        text: "Saturday",
        weekday: 6
      },
      {
        text: "Sunday",
        weekday: 0
      }
    ];

    return (
      <>
        <FormGroup style={{ display: "inline-block" }}>
          <ControlGroup>
            <ButtonGroup style={{ margin: "auto 0" }}>
              {options.map((o, i) => (
                <Button
                  key={i}
                  onClick={() => this._onDepartChange(o.value)}
                  active={this.props.departArrive === o.value}
                >
                  {o.text}
                </Button>
              ))}
            </ButtonGroup>
          </ControlGroup>
          <ControlGroup>
            <TimePicker
              value={moment(time, "hh:mm a").toDate()}
              useAmPm={true}
              onChange={this._onTimeChange}
              showArrowButtons
              style={{
                display: departArrive === "NOW" ? "none" : ""
              }}
            />
          </ControlGroup>
        </FormGroup>
        <FormGroup label="on a">
          <ButtonGroup>
            {dowOptions.map((o, i) => (
              <Button
                key={i}
                onClick={() => this._onDayOfWeekChange(o.weekday)}
                active={moment(date).weekday() === o.weekday}
              >
                {o.text}
              </Button>
            ))}
          </ButtonGroup>
        </FormGroup>
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const {
    departArrive,
    date,
    time,
    startTime,
    endTime
  } = state.otp.currentQuery;
  return {
    config: state.otp.config,
    departArrive,
    date,
    time,
    startTime,
    endTime
  };
};

const mapDispatchToProps = {
  setQueryParam
};

export default connect(mapStateToProps, mapDispatchToProps)(DateTimeSelector);
