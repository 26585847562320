/* @flow */
import React, { Component } from "react";
import { Intent, Spinner } from "@blueprintjs/core";

type Props = {||};

export default class Loading extends Component<Props> {
  props: Props;
  render() {
    return <Spinner intent={Intent.PRIMARY} />;
  }
}
