/* @flow */
import React, { Component } from "react";
import { connect } from "react-redux";

import TripViewer from "./trip-viewer";
import RouteViewer from "./route-viewer";
import { MainPanelContent } from "../../actions/ui";

type Props = { uiState?: {} };

class ViewerContainer extends Component<Props> {
  render() {
    const { uiState } = this.props;

    // check for main panel content
    if (uiState.mainPanelContent === MainPanelContent.ROUTE_VIEWER) {
      return <RouteViewer />;
    }

    if (uiState.viewedTrip) {
      return <TripViewer />;
    }

    // otherwise, return default content
    return <>{this.props.children}</>;
  }
}

// connect to the redux store

const mapStateToProps = (state, ownProps) => {
  return {
    uiState: state.otp.ui
  };
};

export default connect(mapStateToProps)(ViewerContainer);
