// @flow
import qs from "qs";
import moment from "moment";

import { coordsToString, latlonToString } from "../util/map";

/**
 * Get the active search object
 * @param {Object} otpState the OTP state object
 * @returns {Object} an search object, or null if there is no active search
 */

export function getActiveSearch(otpState) {
  return otpState.searches[otpState.activeSearchId];
}

/**
 * Get the active itineraries for the active search, which is dependent on
 * whether non-realtime results should be displayed. Add realtime results here
 * later.
 * @param {Object} otpState the OTP state object
 * @return {Array}      array of itinerary objects from the OTP plan response,
 *                      or null if there is no active search
 */
export function getActiveItineraries(otpState) {
  const search = getActiveSearch(otpState);
  const response = !search ? null : search.nonRealtimeResponse;
  if (!response || !response.plan) return null;
  return response.plan.itineraries;
}

/**
 * Get the active itinerary/profile for the active search object
 * @param {Object} otpState the OTP state object
 * @returns {Object} an itinerary object from the OTP plan response, or null if
 *   there is no active search or itinerary
 */

function getActiveItinerary(otpState) {
  const search = getActiveSearch(otpState);
  const itineraries = getActiveItineraries(otpState);
  if (!itineraries) return null;
  return itineraries.length > search.activeItinerary &&
    search.activeItinerary != null &&
    search.activeItinerary >= 0
    ? itineraries[search.activeItinerary]
    : null;
}

function getActivePreviewItinerary(otpState) {
  const search = getActiveSearch(otpState);
  const itineraries = getActiveItineraries(otpState);
  if (!itineraries) return null;
  return itineraries.length > search.activePreviewItinerary &&
    search.activePreviewItinerary != null &&
    search.activePreviewItinerary >= 0
    ? itineraries[search.activePreviewItinerary]
    : null;
}
export { getActiveItinerary, getActivePreviewItinerary };

/**
 * Determine if the current query has a valid location, including lat/lon
 * @param {Object} otpState the OTP state object
 * @param {string} locationKey the location key ('from' or 'to')
 * @returns {boolean}
 */

function hasValidLocation(otpState, locationKey) {
  return (
    otpState.currentQuery[locationKey] &&
    otpState.currentQuery[locationKey].lat &&
    otpState.currentQuery[locationKey].lon
  );
}
export { hasValidLocation };

/**
 * Determine if the current query is valid
 * @param {Object} otpState the OTP state object
 * @returns {boolean}
 */

function queryIsValid(otpState) {
  return hasValidLocation(otpState, "from") && hasValidLocation(otpState, "to");
  // TODO: add mode validation
  // TODO: add date/time validation
}

function isochroneQueryIsValid(otpState) {
  return hasValidLocation(otpState, "from");
  // TODO: add mode validation
  // TODO: add date/time validation
}
export { queryIsValid, isochroneQueryIsValid };

function getDefaultQueryFromURL() {
  let params = {};
  if (typeof window !== "undefined") {
    params = qs.parse(
      window.decodeURI(window.location.hash).split("#plan?")[1]
    );
  }

  const from = (params.fromPlace && params.fromPlace.split(",")) || [];
  const to = (params.toPlace && params.toPlace.split(",")) || [];
  const ret = {
    departArrive: params.arriveBy === "true" ? "ARRIVE" : "DEPART"
  };

  if (params.datetime) {
    const m = moment(params.datetime);
    ret["date"] = m.format("YYYY-MM-DD");
    ret["time"] = m;
  }

  if (from.length) {
    ret["from"] = {
      name: coordsToString(from) || null,
      lat: parseFloat(from[0].trim(), 10) || null,
      lon: parseFloat(from[1].trim(), 10) || null
    };
  }
  if (to.length) {
    ret["to"] = {
      name: coordsToString(to) || null,
      lat: parseFloat(to[0].trim(), 10) || null,
      lon: parseFloat(to[1].trim(), 10) || null
    };
  }

  return ret;
}

function getQueryAsURLParams(state: { [string]: any }): string {
  const forcedLocalTime = moment(state.date + " " + state.time.format("HH:mm"));
  let params = {
    fromPlace: state.from ? latlonToString(state.from) : null,
    toPlace: state.to ? latlonToString(state.to) : null,
    arriveBy: (state.departArrive === "ARRIVE").toString(),
    datetime: forcedLocalTime.toISOString(true)
  };
  return "#plan?" + qs.stringify(params);
}

export { getDefaultQueryFromURL, getQueryAsURLParams };
