/* @flow */
import React, { Component } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { distanceString } from "../../../util/distance";
import { getStepInstructions } from "../../../util/itinerary";
import { formatDuration } from "../../../util/time";

type Props = {
  activeStep?: number,
  leg?: Object,
  setActiveLeg?: Function,
  setActiveStep?: Function
};

export default class AccessLeg extends Component {
  props: Props;

  _onLegClick = e => {
    const { active, leg, index, setActiveLeg } = this.props;
    if (active) {
      setActiveLeg(null);
    } else {
      setActiveLeg(index, leg);
    }
  };

  _onStepClick(e, step, index) {
    if (index === this.props.activeStep) {
      this.props.setActiveStep(null);
    } else {
      this.props.setActiveStep(index, step);
    }
  }

  render() {
    const { active, activeStep, index, leg } = this.props;
    return (
      <div key={index} className={`leg${active ? " active" : ""} access-leg`}>
        <button className="header" onClick={this._onLegClick}>
          <span>
            <FontAwesomeIcon icon={`chevron-${active ? "down" : "right"}`} />
          </span>
          <span>
            <b>{leg.mode}</b>
          </span>{" "}
          <span className="leg-duration">{formatDuration(leg.duration)}</span>{" "}
          <span className="leg-distance">({distanceString(leg.distance)})</span>
        </button>
        {active && (
          <div className="step-by-step">
            <div className="access-leg">
              {leg.steps.map((step, stepIndex) => {
                const stepIsActive = activeStep === stepIndex;
                return (
                  <button
                    key={stepIndex}
                    className={`step ${stepIsActive ? "active" : ""}`}
                    onClick={e => this._onStepClick(e, step, stepIndex)}
                  >
                    <span className="step-distance">
                      {distanceString(step.distance)}
                    </span>
                    <span className="step-text">
                      {getStepInstructions(step)}
                    </span>
                  </button>
                );
              })}
            </div>
          </div>
        )}
      </div>
    );
  }
}
