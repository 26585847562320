/* @flow */
import React, { Component } from "react";

import AccessLeg from "./access-leg";
import TransitLeg from "./transit-leg";
import { isTransit } from "../../../util/itinerary";

type Props = { itinerary?: Object };

export default class ItineraryDetails extends Component<Props> {
  render() {
    const {
      itinerary,
      activeLeg,
      activeStep,
      setActiveLeg,
      setActiveStep
    } = this.props;
    // <div className="detail">
    return (
      <>
        {itinerary.legs.map((leg, index) => {
          const legIsActive = activeLeg === index;
          return isTransit(leg.mode) ? (
            <TransitLeg
              active={legIsActive}
              index={index}
              key={`${leg.startTime}-${leg.endTime}-${leg.routeId}-${index}`}
              leg={leg}
              setActiveLeg={setActiveLeg}
            />
          ) : (
            <AccessLeg
              active={legIsActive}
              activeStep={activeStep}
              index={index}
              key={`${leg.startTime}-${leg.endTime}-walk-${index}`}
              leg={leg}
              setActiveLeg={setActiveLeg}
              setActiveStep={setActiveStep}
            />
          );
        })}
      </>
    );

    // </div>
  }
}
