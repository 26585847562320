// @flow
import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Intent,
  Button,
  ButtonGroup,
  InputGroup,
  TextArea,
  Dialog,
  Position,
  Toaster,
  Classes,
  FormGroup
} from "@blueprintjs/core";
import { createAction } from "redux-actions";
import { identity } from "lodash";

export const feedbackSubmit = createAction(
  "FEEDBACK_SUBMIT",
  identity,
  ({ meta }) => meta
);

type FeedbackFormFieldNames =
  | "name"
  | "comments"
  | "email"
  | "trip_approx_duration";

type FeedbackFormValuesPayload = {|
  [FeedbackFormFieldNames]: string
|};

type TouchedType = {|
  [FeedbackFormFieldNames]: boolean
|};

type State = {|
  ...FeedbackFormValuesPayload,
  touched: TouchedType,
  attemptedSubmit?: boolean
|};

class FeedbackModalForm extends Component<{}, State> {
  constructor(props) {
    super(props);
    this.form = React.createRef();

    this.state = {
      name: "",
      comments: "",
      email: "",
      trip_approx_duration: "",
      touched: {
        name: false,
        comments: false,
        email: false,
        trip_approx_duration: false
      },
      attemptedSubmit: false
    };
  }

  handleBlur = (
    field: "name" | "comments" | "email" | "trip_approx_duration"
  ) => evt => {
    const newTouched: TouchedType = {
      ...this.state.touched,
      [field]: true
    };
    this.setState({ touched: newTouched });
  };

  validateForm = () => {
    const form = this.form.current;
    if (!form) return {};
    const errors = {};
    if (form.checkValidity() === false) {
      for (let i = 0; i < form.length; i++) {
        const elem = form[i];

        if (!elem.validity.valid && elem.name) {
          errors[elem.name] = elem.validationMessage;
        }
      }
    }
    return errors;
  };

  render() {
    const { visible, onCancel, onSubmit } = this.props;

    const fields: Array<{
      name: FeedbackFormFieldNames,
      label: string,
      props: {},
      clearOnCancel?: boolean,
      isRequired?: boolean
    }> = [
      {
        name: "name",
        label: "Name (required)",
        isRequired: true,
        component: InputGroup,
        props: { required: true }
      },
      {
        name: "email",
        label: "Email",
        component: InputGroup,
        props: { type: "email", required: false }
      },
      {
        name: "trip_approx_duration",
        label: "My trip is now: (required)",
        component: ButtonGroup,
        isRequired: true,
        clearOnCancel: true,
        props: {
          children: [
            { text: "Shorter", value: "shorter" },
            { text: "About the same", value: "about_the_same" },
            { text: "Longer", value: "longer" }
          ].map(x => (
            <Button
              key={x.value}
              value={x.value}
              active={this.state.trip_approx_duration === x.value}
              onBlur={this.handleBlur("trip_approx_duration")}
              onClick={event =>
                this.setState({
                  trip_approx_duration: x.value
                })
              }
            >
              {x.text}
            </Button>
          ))
        }
      },
      {
        name: "comments",
        label: "Comments",
        isRequired: false,
        clearOnCancel: true,
        component: TextArea,
        props: { fill: true, required: false }
      }
    ];
    const errors = fields.reduce((result, item) => {
      if (!result[item.name] && item.isRequired && !this.state[item.name]) {
        result[item.name] = "This field is required";
      }

      return result;
    }, this.validateForm());

    const shouldMarkError = field => {
      const hasError = errors[field];
      const shouldShow =
        this.state.touched[field] || this.state.attemptedSubmit;

      return hasError ? shouldShow : false;
    };

    const cleanUp = () => {
      fields.forEach(field => {
        if (field.clearOnCancel) {
          this.setState({
            [field.name]: ""
          });
        }
      });
      this.setState({
        touched: {
          name: false,
          comments: false,
          email: false,
          trip_approx_duration: false
        },
        attemptedSubmit: false
      });
    };

    const cleanUpAndCancel = () => {
      cleanUp();
      onCancel();
    };

    const checkAndSubmit = () => {
      this.setState({ attemptedSubmit: true });
      const isValid = Object.keys(errors).length === 0;
      if (isValid) {
        onSubmit({
          trip_approx_duration: this.state.trip_approx_duration,
          name: this.state.name,
          comments: this.state.comments,
          email: this.state.email
        });
        cleanUp();
      }
    };

    return (
      <Dialog
        title="Send feedback to Grand River Transit"
        isOpen={visible}
        onClose={cleanUpAndCancel}
      >
        <div className={Classes.DIALOG_BODY}>
          <form ref={this.form}>
            <p>
              Your trip information will be attached to your message and sent to
              Grand River Transit for review.
            </p>
            {fields.map(x => {
              return (
                <FormGroup
                  key={x.name}
                  label={x.label}
                  helperText={shouldMarkError(x.name) ? errors[x.name] : null}
                  intent={shouldMarkError(x.name) ? Intent.DANGER : null}
                >
                  <x.component
                    {...x.props}
                    name={x.name}
                    intent={shouldMarkError(x.name) ? Intent.DANGER : null}
                    value={this.state[x.name]}
                    onChange={event =>
                      this.setState({
                        [x.name]: event.target.value
                      })
                    }
                    onBlur={this.handleBlur(x.name)}
                  />
                </FormGroup>
              );
            })}
          </form>
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button onClick={cleanUpAndCancel}>Cancel</Button>
            <Button onClick={checkAndSubmit} intent={Intent.PRIMARY}>
              Submit
            </Button>
          </div>
        </div>
      </Dialog>
    );
  }
}

type FeedbackModalProps = {|
  hasActiveQuery: boolean,
  routingType: string,
  sendFeedback: FeedbackFormValuesPayload => void,
  setQueryParam: () => any
|};

type FeedbackModalState = {| modalVisible: boolean, confirmLoading: boolean |};

class FeedbackModal extends Component<FeedbackModalProps, FeedbackModalState> {
  state: FeedbackModalState = {
    modalVisible: false,
    confirmLoading: false
  };

  handleSubmit = (values: FeedbackFormValuesPayload) => {
    this.props.sendFeedback(values);
    this.setState({ modalVisible: false });
    Toaster.create({ position: Position.TOP }).show({
      message: "Your feedback has been sent!",
      intent: Intent.SUCCESS
    });
  };

  showModal = () => {
    this.setState({ modalVisible: true });
  };

  handleCancel = () => {
    this.setState({ modalVisible: false });
  };

  render() {
    const { modalVisible } = this.state;
    if (!this.props.hasActiveQuery) {
      return null;
    }

    return (
      <div>
        <Button intent={Intent.PRIMARY} onClick={this.showModal}>
          Send Feedback
        </Button>

        <FeedbackModalForm
          visible={modalVisible}
          onCancel={this.handleCancel}
          onSubmit={this.handleSubmit}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { departArrive, date, time, routingType } = state.otp.currentQuery;
  return {
    hasActiveQuery: state.otp.currentQuery.from !== null,
    config: state.otp.config,
    departArrive,
    date,
    time,
    routingType
  };
};

const mapDispatchToProps = dispatch => {
  return {
    sendFeedback: (values: FeedbackFormValuesPayload) => {
      dispatch(
        feedbackSubmit({
          meta: {
            analytics: {
              eventType: "FEEDBACK",
              eventPayload: {
                tripDuration: values.trip_approx_duration,
                name: values.name,
                comments: values.comments,
                email: values.email
              }
            }
          }
        })
      );
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FeedbackModal);
