/* @flow */
import React, { Component } from "react";
import { Breadcrumbs, Boundary } from "@blueprintjs/core";
import RouteTag from "../../icons/route-tag";
import ModeIcon from "../../icons/mode-icon";
import { formatDuration, formatTime } from "../../../util/time";

type Props = {
  itinerary?: {},
  rightIcon?: () => any
};

export default class ItinerarySummary extends Component<Props> {
  render() {
    const { itinerary, rightIcon } = this.props;

    let legBreadcrumbs = itinerary.legs
      .map((leg, i) => {
        // Skip mid-itinerary walk transfer legs
        if (
          i > 0 &&
          i < itinerary.legs.length - 1 &&
          !leg.transitLeg &&
          itinerary.legs[i - 1].transitLeg &&
          itinerary.legs[i + 1].transitLeg
        ) {
          return null;
        }

        const crumbElements = { disabled: false, current: false };

        // Add the mode icon
        // If a transit leg, add the name (preferably short; long if needed)
        if (leg.transitLeg) {
          crumbElements["text"] = (
            <>
              <ModeIcon mode={leg.mode} />
              <RouteTag leg={leg} fallbackToLongName={true} />
            </>
          );
        } else {
          crumbElements["text"] = <ModeIcon mode={leg.mode} />;
        }

        return crumbElements;
      })
      .filter(Boolean);

    return (
      <div className="summary">
        {rightIcon && (
          <div
            style={{
              position: "relative",
              top: "0",
              right: "0",
              float: "right"
            }}
          >
            {rightIcon}
          </div>
        )}
        <Breadcrumbs items={legBreadcrumbs} collapseFrom={Boundary.END} />
        <div style={{ float: "right" }}>
          {formatTime(itinerary.startTime)}—{formatTime(itinerary.endTime)} (
          {formatDuration(itinerary.duration)})
        </div>
        <div className="clearfix" />
      </div>
    );
  }
}
