/* @flow */
import React, { Component } from "react";
import Control from "react-leaflet-control";
import { connect } from "react-redux";
import { Button, ButtonGroup } from "@blueprintjs/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Map,
  TileLayer,
  LayersControl,
  ZoomControl,
  Popup
} from "react-leaflet";

import {
  DefaultSearchForm,
  ErrorMessage,
  NarrativeRoutingResults,
  ViewerContainer
} from "../../index";

import { LatLngBounds, LatLng } from "leaflet";
import { setLocation } from "../../actions/map";
import { setMapCenter, setMapZoom } from "../../actions/config";

import { constructLocation } from "../../util/map";
import { getActiveItinerary, getActiveSearch } from "../../util/state";
import { getItineraryBounds } from "../../util/itinerary";
import { findAllStops } from "../../actions/api";

import FeedbackModal from "./feedback-modal";
import IsochroneLegend from "./isochrone-legend";

type Props = {
  config?: Object,
  mapClick?: Function,
  allowMapPan: boolean,
  // TODO: rename from action name to avoid namespace conflict?
  setLocation: ({
    type: "from" | "to",
    location: { lng: number, lat: number, reverseGeocode: boolean }
  }) => any,
  onSetLocation?: ({| type: "from" | "to", location: {} |}) => any,
  toggleName?: React.Element
};

class BaseMap extends Component<Props> {
  /* Constructor */

  constructor(props: Props) {
    super(props);

    // For controlled overlays, maintain a map of boolean visibility status,
    // indexed by controlName string
    const overlayVisibility = {};
    React.Children.toArray(this.props.children).forEach(child => {
      if (child.props.controlName && child.props.visible) {
        overlayVisibility[child.props.controlName] = child.props.visible;
      }
    });

    this.state = { overlayVisibility };
  }

  /* Internal Methods */

  _setLocationFromPopup = type => {
    const { setLocation } = this.props;
    const location = constructLocation(this.state.popupPosition.wrap());
    setLocation({ type, location, reverseGeocode: true });
    this.setState({ popupPosition: null });
    if (this.props.onSetLocation) {
      this.props.onSetLocation({ type, location });
    }
  };

  _onClickTo = () => this._setLocationFromPopup("to");

  _onClickFrom = () => this._setLocationFromPopup("from");

  _onLeftClick = e => {
    if (typeof this.props.onClick === "function") this.props.onClick(e);
  };

  _onRightClick = e => {
    this.setState({ popupPosition: e.latlng });
  };

  _onOverlayAdd = evt => {
    const overlayVisibility = { ...this.state.overlayVisibility };
    overlayVisibility[evt.name] = true;
    this.setState({ overlayVisibility });
  };

  _onOverlayRemove = evt => {
    const overlayVisibility = { ...this.state.overlayVisibility };
    overlayVisibility[evt.name] = false;
    this.setState({ overlayVisibility });
  };

  // TODO: make map controlled component
  _mapBoundsChanged = e => {
    // if (this.state.zoomToTarget) {
    //   setTimeout(() => { this.setState({zoomToTarget: false}) }, 200)
    //   return false
    // } else {
    // const zoom = e.target.getZoom()
    const bounds = e.target.getBounds();
    // if (this.props.mapState.zoom !== zoom) {
    //   this.props.updateMapState({zoom})
    // }
    if (!bounds.equals(this.props.mapState.bounds)) {
      this.props.updateMapState({ bounds: e.target.getBounds() });
    }
    // }
  };

  _updateBounds(oldProps, newProps) {
    // TODO: maybe setting bounds ought to be handled in map props...

    const { map } = this.refs;
    if (!map) return;
    if (!newProps.allowMapPan) return;

    const padding = { paddingTopLeft: [30, 70], paddingBottomRight: [30, 30] };

    // Fit map to to entire itinerary if active itinerary bounds changed
    const oldItinBounds =
      oldProps && oldProps.itinerary && getItineraryBounds(oldProps.itinerary);
    const newItinBounds =
      newProps.itinerary && getItineraryBounds(newProps.itinerary);
    if (
      (!oldItinBounds && newItinBounds) ||
      (oldItinBounds && newItinBounds && !oldItinBounds.equals(newItinBounds))
    ) {
      map.leafletElement.flyToBounds(newItinBounds, { ...padding });

      // If no itinerary present but from/to locations are, fit to those
    } else if (newProps.query.from && newProps.query.to) {
      map.leafletElement.flyToBounds(
        [
          [newProps.query.from.lat, newProps.query.from.lon],
          [newProps.query.to.lat, newProps.query.to.lon]
        ],
        { ...padding }
      );
      // If only from or to is set, pan to that
    } else if (newProps.query.from) {
      map.leafletElement.panTo([
        newProps.query.from.lat,
        newProps.query.from.lon
      ]);
    } else if (newProps.query.to) {
      map.leafletElement.panTo([newProps.query.to.lat, newProps.query.to.lon]);
      // Pan to to itinerary step if made active (clicked)
    } else if (
      newProps.itinerary &&
      newProps.activeLeg !== null &&
      newProps.activeStep !== null &&
      newProps.activeStep !== oldProps.activeStep
    ) {
      const leg = newProps.itinerary.legs[newProps.activeLeg];
      const step = leg.steps[newProps.activeStep];
      map.leafletElement.panTo([step.lat, step.lon]);

      // Pan to to itinerary leg if made active (clicked)
    } else if (
      newProps.itinerary &&
      newProps.activeLeg !== oldProps.activeLeg
    ) {
      map.leafletElement.eachLayer(l => {
        if (
          l &&
          l.feature &&
          l.feature.geometry &&
          l.feature.geometry.index === newProps.activeLeg
        ) {
          this.refs.map.leafletElement.fitBounds(l.getBounds());
        }
      });
    }
  }

  /* React Lifecycle methods */

  componentDidMount() {
    this._updateBounds(null, this.props);

    const { map } = this.refs;
    if (!map) return;
    this.props.findAllStops();

    // Something's janky about the first load -- let's reset state.
    setTimeout(() => {
      this.refs.map.leafletElement.invalidateSize();
      this.setState({});
    }, 250);
  }

  componentDidUpdate(prevProps) {
    this._updateBounds(prevProps, this.props);
  }

  // remove custom overlays on unmount
  componentWillUnmount() {
    const lmap = this.refs.map.leafletElement;
    lmap.eachLayer(layer => {
      if (layer) {
        lmap.removeLayer(layer);
      }
    });
  }

  resized() {
    this.refs.map.leafletElement.invalidateSize();
    if (this.props.itinerary) {
      this.refs.map &&
        this.refs.map.leafletElement.fitBounds(
          getItineraryBounds(this.props.itinerary),
          {
            padding: [3, 3]
          }
        );
    }
  }

  render() {
    const { config, children } = this.props;
    const { baseLayers } = this.props.config.map;

    const userControlledOverlays = [];
    const fixedOverlays = [];
    React.Children.toArray(children).forEach(child => {
      if (child.props.controlName) {
        // Add the visibility flag to this layer and push to the interal
        // array of user-controlled overlays
        const visible = this.state.overlayVisibility[child.props.controlName];
        const childWithVisibility = React.cloneElement(child, { visible });
        userControlledOverlays.push(childWithVisibility);
      } else {
        fixedOverlays.push(child);
      }
    });

    const { popupPosition } = this.state;
    const clickToNavigatePopup = popupPosition ? (
      <Popup
        ref="clickPopup"
        key={popupPosition.toString()} // hack to ensure the popup opens only on right click
        position={popupPosition} // FIXME: onOpen and onClose don't seem to work?
        // onOpen={() => this.setState({popupPosition: null})}
        // onClose={() => this.setState({popupPosition: null})}
      >
        <span>
          Plan a trip:
          <ButtonGroup>
            <Button
              icon={<FontAwesomeIcon icon="map-marker" />}
              onClick={this._onClickFrom}
            >
              From here
            </Button>
            <Button
              icon={<FontAwesomeIcon icon="flag-checkered" />}
              onClick={this._onClickTo}
            >
              To here
            </Button>
          </ButtonGroup>
        </span>
      </Popup>
    ) : null;

    const center =
      config.map && config.map.initLat && config.map.initLon
        ? [config.map.initLat, config.map.initLon]
        : null;

    const lowerSidebar = (
      <ViewerContainer className="viewer-container">
        <IsochroneLegend />
        <ErrorMessage />
        <NarrativeRoutingResults />
      </ViewerContainer>
    );

    return (
      <Map
        zoomControl={false}
        ref="map"
        className="map"
        center={center}
        maxBounds={new LatLngBounds(new LatLng(43, -81), new LatLng(44, -80))}
        minZoom={7}
        maxZoom={20}
        maxBoundsViscosity={0.8}
        zoom={config.map.initZoom}
        onClick={this._onLeftClick}
        onContextMenu={this._onRightClick}
        onOverlayAdd={this._onOverlayAdd}
        onOverlayRemove={this._onOverlayRemove}
      >
        {/* Create the layers control, including base map layers and any
         * user-controlled overlays. */}

        <LayersControl position="topright">
          {/* base layers  */
          baseLayers &&
            baseLayers.map((l, i) => (
              <LayersControl.BaseLayer
                name={l.name}
                disabled={baseLayers.length === 1}
                checked={i === 0}
                key={i}
              >
                <TileLayer
                  url={l.url}
                  attribution={l.attribution}
                  detectRetina
                />
              </LayersControl.BaseLayer>
            ))}
          {/* user-controlled overlay layers */
          userControlledOverlays.map((child, i) => {
            return (
              <LayersControl.Overlay
                key={i}
                name={child.props.controlName}
                checked={child.props.visible}
              >
                {child}
              </LayersControl.Overlay>
            );
          })}
        </LayersControl>
        {/* Add the fixed, i.e. non-user-controllable overlays */}
        {fixedOverlays}
        <Control position="topleft" role="navigation">
          <div
            onMouseEnter={e => {
              this.refs.map.leafletElement.scrollWheelZoom.disable();
            }}
            onMouseLeave={e => {
              this.refs.map.leafletElement.scrollWheelZoom.enable();
            }}
          >
            <DefaultSearchForm results={lowerSidebar} />
          </div>
        </Control>
        <Control position="bottomleft" />
        <Control position="bottomright">
          <ZoomControl position="bottomright" />
          <FeedbackModal />
        </Control>
        {/* Add the location selection popup, if visible */}
        {clickToNavigatePopup}
      </Map>
    );
  }
}

// connect to the redux store

const mapStateToProps = (state, ownProps) => {
  const activeSearch = getActiveSearch(state.otp);
  return {
    activeLeg: activeSearch && activeSearch.activeLeg,
    activeStep: activeSearch && activeSearch.activeStep,
    config: state.otp.config,
    mapState: state.otp.mapState,
    allowMapPan: state.otp.currentQuery.allowMapPan,
    isFromSet:
      state.otp.currentQuery.from &&
      state.otp.currentQuery.from.lat !== null &&
      state.otp.currentQuery.from.lon !== null,
    isToSet:
      state.otp.currentQuery.to &&
      state.otp.currentQuery.to.lat !== null &&
      state.otp.currentQuery.to.lon !== null,
    itinerary: getActiveItinerary(state.otp),
    query: state.otp.currentQuery
  };
};

const mapDispatchToProps = {
  setLocation,
  setMapCenter,
  setMapZoom,
  findAllStops
};

// allow access to the wrapped BaseMap (for access to resized())
export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true
})(BaseMap);
