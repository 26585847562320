import "react-app-polyfill/ie11";
import "./polyfills";

// import necessary React/Redux libraries
import React, { Component } from "react";
import { createRoot } from "react-dom/client";
import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import { Provider, connect } from "react-redux";
import * as Sentry from "@sentry/browser";
import { ReportingObserver, ExtraErrorData } from "@sentry/integrations";
import sentry from "./lib/reducers/sentry";
import thunk from "redux-thunk";
import { createLogger } from "redux-logger";

import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faRss,
  faExchangeAlt,
  faUndo,
  faChevronLeft,
  faChevronRight,
  faBus,
  faMapPin,
  faSubway,
  faWalking,
  faBicycle,
  faArrowLeft,
  faMapMarker,
  faChevronDown,
  faChevronUp,
  faClock,
  faCheckCircle,
  faCircle,
  faFlagCheckered,
  faCalendar,
  faTimes,
  faSearch,
  faGlobeAmericas,
  faExclamationCircle,
  faShare
} from "@fortawesome/free-solid-svg-icons";

// import OTP-RR components
import { DefaultMap, AppMenu, createOtpReducer } from "./lib";

// load the OTP configuration
import otpConfig from "./config.json";
import { createAnalytics } from "./lib/util/analytics";
import { setQueryParam } from "./lib/actions/form";
import "./index.scss";

library.add(
  faExchangeAlt,
  faUndo,
  faBus,
  faSubway,
  faWalking,
  faBicycle,
  faRss,
  faFlagCheckered,
  faMapPin,
  faArrowLeft,
  faMapMarker,
  faChevronDown,
  faChevronUp,
  faChevronRight,
  faChevronLeft,
  faClock,
  faCalendar,
  faCircle,
  faCheckCircle,
  faTimes,
  faSearch,
  faGlobeAmericas,
  faExclamationCircle,
  faShare
);

if (process.env.REACT_APP_SENTRY_RELEASE) {
  Sentry.init({
    dsn: "https://20215f8eb7c44182b1301a3d060d7e14@sentry.io/1381949",
    integrations: [new ReportingObserver(), new ExtraErrorData()],
    release: process.env.REACT_APP_SENTRY_RELEASE
  });
}

if (process.env.NODE_ENV !== "production") {
  const axe = require("@axe-core/react");

  const ReactDOM = require("react-dom");
  axe(React, ReactDOM, 1000);
}

// set up the Redux store
const combinedReducer = combineReducers({
  otp: createOtpReducer(otpConfig) // add optional initial query here
  // add your own reducers if you want
});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const middleware = applyMiddleware(
  thunk,
  sentry,
  createLogger(),
  createAnalytics({ token: "ndlh4iXaWI14CkW3F5u1d1SP489t7MuP6Kp50W8q" })
);
const store = createStore(combinedReducer, composeEnhancers(middleware));

// define a simple responsive UI using Bootstrap and OTP-RR
class OtpRRExample extends Component {
  componentDidMount() {
    if (window.location.hash.length > 5) {
      this.props.setQueryParam && this.props.setQueryParam({});
    }
  }

  render() {
    /** shared components **/
    const map = (
      <div className="map-container" style={{ flex: "1" }}>
        <DefaultMap />
      </div>
    );

    const desktopView = (
      <div
        style={{
          height: "100vh",
          width: "100vw",
          display: "flex",
          flexDirection: "column"
        }}
      >
        <div className="header" role="banner">
          <AppMenu />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            height: "100%"
          }}
          role="main"
        >
          {map}
        </div>
      </div>
    );

    /** the main webapp **/
    return desktopView;
  }
}

const mapStateToProps = (state, ownProps) => {
  return {};
};
const mapDispatchToProps = { setQueryParam };

const ConnectedOTP = connect(mapStateToProps, mapDispatchToProps)(OtpRRExample);

// render the app

const container = document.getElementById("root");
const root = createRoot(container);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <ConnectedOTP />
    </Provider>
  </React.StrictMode>
);
