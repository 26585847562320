/* @flow */
import React, { Component } from "react";
import { Button } from "@blueprintjs/core";
import { connect } from "react-redux";

import { switchLocations, clearLocation } from "../../actions/map";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

type Props = {
  onClick?: () => any,
  switchLocations: () => any,
  clearLocations: () => any,
  icon: string,
  content: ?string,
  action: "switch" | "clear"
};

const ariaLabelForAction = {
  switch: "Switch origin and destination locations",
  clear: "Clear origin and destination locations"
};

const defaultContentForAction = {
  switch: "Switch",
  clear: "Clear"
};

class SwitchClearButton extends Component<Props> {
  _onClick = () => {
    switch (this.props.action) {
      case "switch":
        return this.props.switchLocations();
      case "clear":
        return this.props.clearLocations();
      default:
        return null;
    }
  };

  render() {
    const {
      content,
      action,
      onClick,
      icon,
      switchLocations,
      clearLocations,
      ...props
    } = this.props;
    return (
      <Button
        className="switch-button"
        onClick={onClick || this._onClick}
        {...props}
        icon={<FontAwesomeIcon icon={icon} />}
        text={content || defaultContentForAction[action]}
        aria-label={ariaLabelForAction[action]}
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {};
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    switchLocations: () => {
      dispatch(switchLocations());
    },
    clearLocations: () => {
      dispatch(clearLocation({ type: "from" }));
      dispatch(clearLocation({ type: "to" }));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SwitchClearButton);
