/* @flow */
import React, { Component } from "react";
import { connect } from "react-redux";

import Endpoint from "./endpoint";
import { setLocation } from "../../actions/map";

type Props = { query?: Object };

class EndpointsOverlay extends Component {
  props: Props;
  render() {
    const { from, to } = this.props.query;
    return (
      <div>
        {from && <Endpoint type="from" location={from} {...this.props} />}
        {to && <Endpoint type="to" location={to} {...this.props} />}
      </div>
    );
  }
}

// connect to the redux store

const mapStateToProps = (state, ownProps) => {
  return {
    query: state.otp.currentQuery
  };
};

const mapDispatchToProps = {
  setLocation
};

export default connect(mapStateToProps, mapDispatchToProps)(EndpointsOverlay);
