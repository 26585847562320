/* @flow */
import React, { Component } from "react";
import { connect } from "react-redux";
import { GeoJSON } from "react-leaflet";

import { setActiveLeg } from "../../actions/narrative";
import { getActiveSearch } from "../../util/state";
import { getIsochroneColor } from "../../util/isochrone";

type Props = {
  isochrones?: {}
};

class IsochroneOverlay extends Component<Props> {
  props: Props;

  render() {
    const { isochrones } = this.props;
    if (!isochrones) {
      return null;
    }

    const style = feature => {
      return {
        opacity: 0.1,
        weight: 1,
        color: getIsochroneColor(feature.properties.time)
        // color: "hsl(260, 30%, " + (feature.properties.time / 3600) * 100 + "%)"
      };
    };

    return (
      <div>
        <GeoJSON data={isochrones} style={style} />
      </div>
    );
  }
}

// connect to the redux store

const mapStateToProps = (state, ownProps) => {
  const activeSearch = getActiveSearch(state.otp);
  return {
    isochrones: activeSearch && activeSearch.isochroneResponse
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setActiveLeg: (index, leg) => {
      dispatch(setActiveLeg({ index, leg }));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(IsochroneOverlay);
