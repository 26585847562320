/* @flow */
import React, { Component } from "react";
import { connect } from "react-redux";

import { setActiveLeg } from "../../actions/narrative";
import {
  getActiveItinerary,
  getActiveSearch,
  getActivePreviewItinerary
} from "../../util/state";
import ItinerarySteps from "./itinerary-steps";
import ItineraryLegs from "./itinerary-legs";

type Props = {
  activeLeg?: number,
  activeStep?: number,
  itinerary?: Object
};

class ItineraryOverlay extends Component {
  props: Props;

  render() {
    const { activeLeg, activeStep, itinerary } = this.props;
    if (!itinerary) return null;
    return (
      <div>
        <ItineraryLegs
          itinerary={itinerary}
          activeLeg={activeLeg}
          setActiveLeg={this.props.setActiveLeg}
        />
        <ItinerarySteps
          itinerary={itinerary}
          activeLeg={activeLeg}
          activeStep={activeStep}
        />
      </div>
    );
  }
}

// connect to the redux store

const mapStateToProps = (state, ownProps) => {
  const activeSearch = getActiveSearch(state.otp);
  return {
    itinerary:
      getActiveItinerary(state.otp) || getActivePreviewItinerary(state.otp),
    activeLeg: activeSearch && activeSearch.activeLeg,
    activeStep: activeSearch && activeSearch.activeStep
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setActiveLeg: (index, leg) => {
      dispatch(setActiveLeg({ index, leg }));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ItineraryOverlay);
