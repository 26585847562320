import moment from "moment";
import { isTransit, isAccessMode, isCar } from "../util/itinerary";

/**
 * name: the default name of the parameter used for internal reference and API calls
 *
 * routingTypes: array of routing type(s) (ITINERARY, PROFILE, or both) this param applies to
 *
 * applicable: an optional function (accepting the current full query as a
 *   parameter) indicating whether this query parameter is applicable to the query.
 *   (Applicability is assumed if this function is not provided.)
 *
 * default: the default value for this parameter
 *
 * itineraryRewrite: an optional function for translating the key and/or value
 *   for ITINERARY mode only (e.g. 'to' is rewritten as 'toPlace'). Accepts the
 *   intial internal value as a function parameter.
 *
 * profileRewrite: an optional function for translating the value for PROFILE mode
 *
 * label: a text label for for onscreen display. May either be a text string or a
 *   function (accepting the current full query as a parameter) returning a string
 *
 * selector: the default type of UI selector to use in the form. Can be one of:
 *   - DROPDOWN: a standard drop-down menu selector
 *
 * options: an array of text/value pairs used with a dropdown selector
 *
 * TODO: validation system for rewrite functions and/or better user documentation
 * TODO: alphabetize below list
 */

const queryParams = [
  {
    name: "cutoff",
    routingTypes: ["ISOCHRONE"],
    default: [15, 30, 45, 60].map(x => `${x}m`)
  },
  {
    /* from - the trip origin. stored internally as a location (lat/lon/name) object  */
    name: "from",
    routingTypes: ["ITINERARY", "PROFILE", "ISOCHRONE"],
    default: null,
    itineraryRewrite: value => ({ fromPlace: `${value.lat},${value.lon}` }),
    isochroneRewrite: value => ({ location: `${value.lat},${value.lon}` }),
    profileRewrite: value => ({ from: { lat: value.lat, lon: value.lon } })
  },

  {
    /* to - the trip destination. stored internally as a location (lat/lon/name) object  */
    name: "to",
    routingTypes: ["ITINERARY", "PROFILE"],
    default: null,
    itineraryRewrite: value => ({ toPlace: `${value.lat},${value.lon}` }),
    profileRewrite: value => ({ to: { lat: value.lat, lon: value.lon } })
  },

  {
    /* date - the date of travel, in MM-DD-YYYY format */
    name: "date",
    routingTypes: ["ITINERARY", "PROFILE"],
    default: new Date().toISOString().split("T")[0]
  },

  {
    /* time - the arrival/departure time for an itinerary trip, in HH:MM format */
    name: "time",
    routingTypes: ["ITINERARY", "ISOCHRONE"],
    default: moment(),
    itineraryRewrite: value => ({ time: value.format("H:mm") }),
    isochroneRewrite: value => ({ time: value.toISOString() })
  },

  {
    /* departArrive - whether this is a depart-at, arrive-by, or leave-now trip */
    name: "departArrive",
    routingTypes: ["ITINERARY"],
    default: "DEPART",
    itineraryRewrite: value => ({ arriveBy: value === "ARRIVE" })
  },

  {
    /* startTime - the start time for a profile trip, in HH:MM format */
    name: "startTime",
    routingTypes: ["PROFILE"],
    default: "07:00"
  },

  {
    /* endTime - the end time for a profile trip, in HH:MM format */
    name: "endTime",
    routingTypes: ["PROFILE"],
    default: "09:00"
  },

  {
    /* mode - the allowed modes for a trip, as a comma-separated list */
    name: "mode",
    routingTypes: ["ITINERARY", "PROFILE"],
    default: "WALK,TRANSIT", // TODO: make this dependent on routingType?
    profileRewrite: value => {
      const accessModes = [];
      const directModes = [];
      const transitModes = [];

      if (value && value.length > 0) {
        value.split(",").forEach(m => {
          if (isTransit(m)) transitModes.push(m);
          if (isAccessMode(m)) {
            accessModes.push(m);
            // TODO: make configurable whether direct-driving is considered
            if (!isCar(m)) directModes.push(m);
          }
        });
      }

      return { accessModes, directModes, transitModes };
    }
  },

  {
    /* showIntermediateStops - whether response should include intermediate stops for transit legs */
    name: "showIntermediateStops",
    routingTypes: ["ITINERARY"],
    default: true
  },

  {
    /* maxWalkDistance - the maximum distance in meters the user will walk. */
    name: "maxWalkDistance",
    routingTypes: ["ITINERARY"],
    applicable: query => query.mode && query.mode.indexOf("WALK") !== -1,
    default: 800,
    selector: "DROPDOWN",
    label: "Maximum Walk",
    options: [
      {
        text: "200 metres",
        value: 200
      },
      {
        text: "400 metres",
        value: 400
      },
      {
        text: "800 metres",
        value: 800
      },
      {
        text: "1.5 kilometres",
        value: 1500
      },
      {
        text: "3 kilometres",
        value: 3
      }
    ]
  },

  {
    /* maxBikeDistance - the maximum distance in meters the user will bike. Not
     * actually an OTP parameter (maxWalkDistance doubles for biking) but we
     * store it separately internally in order to allow different default values,
     * options, etc.  Translated to 'maxWalkDistance' via the rewrite function.
     */
    name: "maxBikeDistance",
    routingTypes: ["ITINERARY"],
    applicable: query => query.mode && query.mode.indexOf("BICYCLE") !== -1,
    default: 2000, // 2 km
    selector: "DROPDOWN",
    label: "Maximum Bike",
    options: [
      {
        text: "500 metres",
        value: 500
      },
      {
        text: "1 kilometre",
        value: 1000
      },
      {
        text: "2 kilometres",
        value: 2000
      },
      {
        text: "5 kilometres",
        value: 5000
      },
      {
        text: "10 kilometres",
        value: 10000
      },
      {
        text: "20 kilometres",
        value: 20000
      }
    ],
    itineraryRewrite: value => ({ maxWalkDistance: value })
  },

  {
    /* optimize -- how to optimize an itinerary trip */
    name: "optimize",
    routingTypes: ["ITINERARY"],
    default: "QUICK",
    selector: "DROPDOWN",
    label: "Optimize for",
    options: [
      {
        text: "Speed",
        value: "QUICK"
      },
      {
        text: "Transfers",
        value: "TRANSFERS"
      }
    ]
  },

  {
    /* maxWalkTime -- the maximum time the user will spend walking in minutes */
    name: "maxWalkTime",
    routingTypes: ["PROFILE"],
    default: 15,
    selector: "DROPDOWN",
    label: "Max Walk Time",
    applicable: query => query.mode && query.mode.indexOf("WALK") !== -1,
    options: [
      {
        text: "5 minutes",
        value: 5
      },
      {
        text: "10 minutes",
        value: 10
      },
      {
        text: "15 minutes",
        value: 15
      },
      {
        text: "20 minutes",
        value: 20
      },
      {
        text: "30 minutes",
        value: 30
      },
      {
        text: "45 minutes",
        value: 45
      },
      {
        text: "1 hour",
        value: 60
      }
    ]
  },

  {
    /* walkSpeed -- the user's walking speed in m/s */
    name: "walkSpeed",
    routingTypes: ["ITINERARY", "PROFILE"],
    default: 1.39,
    selector: "DROPDOWN",
    label: "Walk Speed",
    applicable: query => query.mode && query.mode.indexOf("WALK") !== -1,
    options: [
      {
        text: "3 km/h",
        value: 0.83
      },
      {
        text: "5 km/h",
        value: 1.39
      },
      {
        text: "7 km/h",
        value: 1.94
      }
    ]
  },

  {
    /* maxBikeTime -- the maximum time the user will spend biking in minutes */
    name: "maxBikeTime",
    routingTypes: ["PROFILE"],
    default: 20,
    selector: "DROPDOWN",
    label: "Max Bike Time",
    applicable: query => query.mode && query.mode.indexOf("BICYCLE") !== -1,
    options: [
      {
        text: "5 minutes",
        value: 5
      },
      {
        text: "10 minutes",
        value: 10
      },
      {
        text: "15 minutes",
        value: 15
      },
      {
        text: "20 minutes",
        value: 20
      },
      {
        text: "30 minutes",
        value: 30
      },
      {
        text: "45 minutes",
        value: 45
      },
      {
        text: "1 hour",
        value: 60
      }
    ]
  },

  {
    /* bikeSpeed -- the user's bikeSpeed speed in m/s */
    name: "bikeSpeed",
    routingTypes: ["ITINERARY", "PROFILE"],
    default: 4.17,
    selector: "DROPDOWN",
    label: "Bicycle Speed",
    applicable: query => query.mode && query.mode.indexOf("BICYCLE") !== -1,
    options: [
      {
        text: "10 km/h",
        value: 2.78
      },
      {
        text: "15 km/h",
        value: 4.17
      },
      {
        text: "18 km/h",
        value: 5
      },
      {
        text: "20 km/h",
        value: 5.56
      }
    ]
  }
];

export default queryParams;
