/* @flow */
import React, { Component } from "react";
import Divicon from "@lighthouse/react-leaflet-div-icon";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import lonlat from "@conveyal/lonlat";
import RouteTag from "../icons/route-tag";

import polyline from "@mapbox/polyline";

import { isTransit, getStepInstructions } from "../../util/itinerary";

type Props = { itinerary?: Object };

export default class ItinerarySteps extends Component {
  props: Props;
  addItineraryStop(array, item) {
    if (item.stopId && array.indexOf(item.stopId) === -1) {
      array.push(item);
    }
  }
  render() {
    const { itinerary, activeLeg, activeStep } = this.props;
    let steps = [];
    itinerary.legs.forEach((l, legIndex) => {
      steps = [
        ...steps,
        ...l.steps.map((s, stepIndex) => {
          s.legIndex = legIndex;
          s.stepIndex = stepIndex;
          return s;
        })
      ];
    });
    return (
      <div>
        {itinerary.legs.map(leg => {
          if (!isTransit(leg.mode)) {
            return null;
          }
          const geojson = polyline.toGeoJSON(leg.legGeometry.points);
          return (
            <Divicon
              key={`${leg.mode}-${Math.random()}`}
              iconSize={[40, 16]}
              title={leg.routeShortName}
              position={lonlat.fromGeoJSON(
                geojson.coordinates[Math.floor(geojson.coordinates.length / 2)]
              )}
            >
              <RouteTag leg={leg} />
            </Divicon>
          );
        })}
        {steps.map((step, index) => {
          if (step.relativeDirection === "DEPART") {
            return null;
          }
          const active =
            step.legIndex === activeLeg && step.stepIndex === activeStep;
          return (
            <Divicon
              title={getStepInstructions(step)}
              position={[step.lat, step.lon]}
              key={index}
            >
              <FontAwesomeIcon
                icon="circle"
                style={{
                  color: active ? "yellow" : "",
                  zIndex: active ? "9000" : null
                }}
              />
            </Divicon>
          );
        })}
      </div>
    );
  }
}
